
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { SocketioService } from '../../services/socket/socketio.service';
import { ConnectionService } from 'ng-connection-service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user/user.service';


@Component({
  selector: 'app-stadistic',
  templateUrl: './stadistic.component.html',
  styleUrls: ['./stadistic.component.css']
})
export class StadisticComponent implements OnInit {


 
  usersActives:any = [];
  usersMsg:any = [];
  isAuth:any;
  
form:any  = {
  user:"",
  mensaje:"",
}

uid:any;
loading:any;

stadistic:any;
shops:any;
type:any = '';

  constructor(
    public _auth : AuthService,
    public router: Router,
    private connectionService: ConnectionService,
    private offcanvasService: NgbOffcanvas,
    public socketService: SocketioService,
    private sanitizer: DomSanitizer,
    private _user: UserService


 ) {
 }


  ngOnInit() {
    this.loading = true;

    this.socketService.resetstadistic.subscribe((stadistic: any) => {
      
      this.getshops();
      this.getStadistic();

    });

  
    this.socketService.usersActives.subscribe((resp: any) => {
     // this.usersActives = resp.filter((item:any) => item.uid != this.uid);

     this.usersActives = resp;


    });

    this.socketService.usersMsg$.subscribe((rep: any) => {


      this.usersMsg = rep;


    });


    

    this._auth.auth$.subscribe((rep: any) => {

      if(rep){
        this.uid = this._auth.sessionUser?.uid;
        
      }
      this.isAuth = rep;

    });


  }
  
  

  getStadistic = async () => {

    this.loading = true;

    try {

      const resp = await this.getStadisticServer({shop:this.type});

      this.stadistic = resp?.res;
      this.loading = false;

      
    } catch (error) {
      console.error(error);
      this.loading = false;

    } 

  }


  getStadisticServer = async (params:any) => {
    try{
        return this._user.getStadistic(params);

      } catch (error) {
        console.error(error);
        
        
      }
  }


enviarMensaje(){

  this.socketService.mensajePrivado(this.form.user,this.form.mensaje);

  this.socketService.setUsersMsg( { de: `Tu`,mensaje: this.form.mensaje });

  this.form  = {
    user:"",
    mensaje:"",
  }

}


sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}



getshops = async () => {
  try {

    const resp = await this.getshopsServer();

    debugger
    this.shops = resp?.res;
    
    
  } catch (error) {
    console.error(error);

  } 

 


}


getshopsServer = async () => {
  try{
      return this._user.getshops({});

    } catch (error) {
      console.error(error);
      
      
    }
}


setShop(){

  this.getStadistic();

}


}

