import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.css'],
  providers: [DatePipe]

})
export class PlansComponent implements OnInit {
  plans:any ;
  plans_loading:any ;
  status_plans :any = true;
  plans_active :any;
  plans_inactive :any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    private offcanvasService: NgbOffcanvas,
    public _auth: AuthService,

  ) { }

  

  ngOnInit(): void {
    
    this.getPlans();

    debugger

  }



getPlans = async (plans_loading = true) =>  { 

try {

   this.plans_loading= plans_loading;
     
 let plans =  await this._user.getplans({});
 this.plans = plans?.res;

   this.plans_active = ( this.plans?.filter((item:any) => item.status == true))?.length > 0 ? true : false;
   this.plans_inactive = ( this.plans?.filter((item:any) => item.status != true))?.length > 0 ? true : false;


 }  catch (error:any) {
     console.log(error);
}
  this.plans_loading = false;

}



pocisionBottom = async (content: TemplateRef<any>,data:any= {}) =>  {

  try {

      this.offcanvasService.open(content, { position: 'bottom',keyboard: false} );

  } catch (error) {
    console.log(error);
    
  }
     
}


deletePlan= async (code_plan:any) => {

  try {
    debugger
    let resp = await this._user.deletePlan({code_plan});


    alertifyjs.success('eliminación exitosa');
   //  this.router.navigate([environment.loginRoute], {});
   this.getPlans(false);

   } catch (error:any) {
     
      alertifyjs.error('Error al eliminación plan');
      console.error(error);
   }

}


activePlan= async (code_plan:any) => {

  try {
    debugger
    let resp = await this._user.activePlan({code_plan});


    alertifyjs.success('activación exitosa');
   //  this.router.navigate([environment.loginRoute], {});
   this.getPlans(false);

   } catch (error:any) {
     
      alertifyjs.error('Error al activar plan');
      console.error(error);
   }

}



close(){
  this.offcanvasService.dismiss("");
  this.getPlans(false);

}


}

