

import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { SocketioService } from '../../services/socket/socketio.service';
import { ConnectionService } from 'ng-connection-service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { UserService } from 'src/app/services/user/user.service';
import { MbscDatetimeOptions, mobiscroll } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};

const now = new Date();

//const week = [ new Date(now.getFullYear(),0, 1),now];
let week = [ new Date() ,new Date()];


@Component({
  selector: 'app-stadistic-ejecutives',
  templateUrl: './stadistic-ejecutives.component.html',
  styleUrls: ['./stadistic-ejecutives.component.css'],
  providers: [DatePipe]

})
export class StadisticEjecutivesComponent implements OnInit {

  usersActives:any = [];
  usersMsg:any = [];
  isAuth:any;
  rows:any = [];

  

uid:any;
loading:any;

stadistic:any;
shops:any;
type:any = '';



  constructor(
    public _auth : AuthService,
    public router: Router,
    private connectionService: ConnectionService,
    private offcanvasService: NgbOffcanvas,
    public socketService: SocketioService,
    private sanitizer: DomSanitizer,
    private _user: UserService,
    private datePipe: DatePipe,


 ) {
 }


 range: Array < Date > = week;
 date: Array < Date > = [];
 nonForm: Array < Date > = [];
 external = week;
 d1:any;
 d2:any; 


 dateSetting3: MbscDatetimeOptions = {
   lang: 'es',
   theme: 'ios',
   themeVariant: 'light',
   max:now,
   responsive: {
     small: {
         controls: ['calendar'],
         display: 'center'
     },
     medium: {
         display: 'bubble',
     }
 }
 
 };

 birthday = new Date();
date_base: any = new Date;
date_base_string :any;

mobileSettings: MbscDatetimeOptions = {
 display: 'bottom',
 themeVariant: 'dark',
};


desktopSettings: MbscDatetimeOptions = {
 display: 'bubble',
 touchUi: false
};

  ngOnInit() {
    this.getshops();
    this.loading = true;

    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
  

    this.socketService.resetDataClientCode.subscribe((person_code: any) => {

      this.getStadistic();

      
    });
    

    // this.socketService.resetstadistic.subscribe((stadistic: any) => {
    //         this.getStadistic();

    // });

  
    this._auth.auth$.subscribe((rep: any) => {

      if(rep){
        this.uid = this._auth.sessionUser?.uid;
        
      }
      this.isAuth = rep;

    });


  }
  
  

  getStadistic = async () => {

    this.loading = true;

    try {

      let query =  {
        init:this.d1,
        end:this.d2
      };

      const resp = await this.ejecutives_peoples(query);

      this.stadistic = resp?.resp;

      let stadistics:any = {};
        this.stadistic?.ejecutives?.map((item:any)=>{

          let shop = item.shop?item.shop:'sin_asignar';

         if(!stadistics[shop]) {

             stadistics[shop] = {ejecutives: [item],total:item.count,shop};

         }else{

          stadistics[shop] = {ejecutives: [...stadistics[shop].ejecutives,item] , total:(stadistics[shop].total + item.count),shop:shop };

         }

        });


        let rows:any = [];
        for (const key in stadistics) {

          rows = [...rows,stadistics[key]];

        }

        this.rows = rows;
      this.loading = false;

    } catch (error) {
      console.error(error);
      this.loading = false;

    } 

  }


  ejecutives_peoples = async (params:any) => {
    try{
        return this._user.ejecutives_peoples(params);

      } catch (error) {
        console.error(error);
        
        
      }
  }

  birthdaySet() {  
    
        this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
        this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
       this.getStadistic();
        
  }
      

sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}



getshops = async () => {
  try {

    const resp = await this.getshopsServer();
    this.shops = resp?.res;

    let shops_resp = resp?.res;
    let shops:any = {};


    shops_resp.map((value:any)=>{

      shops[value.code] = value?.name;

    });

    this.shops = shops;
    
    
  } catch (error) {
    console.error(error);

  } 

 


}


getshopsServer = async () => {
  try{
      return this._user.getshops({});

    } catch (error) {
      console.error(error);
      
      
    }
}

}


