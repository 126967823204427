
import { DatePipe } from '@angular/common';
import { noUndefined } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import { mobiscroll, MbscDatetimeOptions, MbscFormOptions } from '@mobiscroll/angular';

import * as alertifyjs from 'alertifyjs';
import { SocketioService } from 'src/app/services/socket/socketio.service';

import Swal from'sweetalert2';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};


const now = new Date();

//const week = [ new Date(now.getFullYear(),0, 1),now];
let week = [ new Date() ,new Date()];

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
  providers: [DatePipe]

})



export class PaymentsComponent implements OnInit {

  queryParams: any;
  person: any;
  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  loading_person = false;
  loading = false;

  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
  type_approved: any;
  clientQuota:any;
  invoiceCredits: any = [];
  resp_paymentsCredits: any = [];
  installments: any = [];
    view = 1;
    invoiceSelected:any;
    installMentSelected:any;
    payment:any;
    loadcon:any;
    loadcon2:any;
    
    filter:any={
      type:'p',
      account:''
    }

    accountPayment_load:any;
    accounts:any;

    autopago: any = [{key: "BA", name: "AUTO: BANCAAMIGA"}, {key: "MERCHANT", name: "AUTO: MERCHANT"}];

   page = 1; // Current page number
   limit = 100; // Number of documents per page

   range: Array < Date > = week;
   date: Array < Date > = [];
   nonForm: Array < Date > = [];
   external = week;
   d1:any;
   d2:any; 
  
  
   dateSetting3: MbscDatetimeOptions = {
     lang: 'es',
     theme: 'ios',
     themeVariant: 'light',
     max:now,
     responsive: {
       small: {
           controls: ['calendar'],
           display: 'center'
       },
       medium: {
           display: 'bubble',
       }
   }
   
   };
  
   birthday = new Date();
  date_base: any = new Date;
  date_base_string :any;
  
  mobileSettings: MbscDatetimeOptions = {
   display: 'bottom',
   themeVariant: 'dark',
  };
  
  
  desktopSettings: MbscDatetimeOptions = {
   display: 'bubble',
   touchUi: false
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    public socketService: SocketioService


  ) { }

  ngOnInit(): void {



    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');

    
    this.loading_person = true;
    this.getPayments();
    this.getAccountPayment();

    this.socketService.resetstadistic.subscribe((stadistic: any) => {
/*
      this.getPayments();
      this.getAccountPayment();
*/
    });

  }

  getPayments = async () => {

    try {
      this.loading_person = true;

      const resp = await this.listPaymentsCredits();
      let result = resp?.res;

      let resp_paymentsCredits:any = this.resp_paymentsCredits;
      this.resp_paymentsCredits =resp_paymentsCredits.concat(result);

    } catch (error) {
      console.error(error);

    } 

    this.loading_person = false;

  }

  concilidarPago = async (payment:any) => {
    let action = 'Conciliar'

    Swal.fire({
      title: `¿Deseas ${action} este pago?`,
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Si, ${action}!`
    }).then((result) => {
      if (result.isConfirmed) {
        

        this.concilidarPagoQuestion(payment);

      }
    });

  }


  cancelPayment = async (payment:any) => {

    let action = 'Anular'


    Swal.fire({
      title: "¿Deseas Anular este pago?",
      text: "¡No podrás revertir esto!",
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      input: "textarea",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonText: `Si, ${action}!`,
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        try {
        
          if (login?.trim() == '') {
            return Swal.showValidationMessage(`Debe añadir una razón de anulación`);
          }

        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {

         this.cancelPaymentQuestion(payment,result?.value);

      }
    });
    debugger

  }



   concilidarPagoQuestion = async (payment:any) => {
    this.loadcon = true;
    try {
      const resp = await this._user.reconciledPayment({ code_payment: payment });
      const resp_paymentsCredits = await this.listPaymentsCredits();
      this.resp_paymentsCredits = resp_paymentsCredits?.res;

  
      if(resp?.res){


        this.getPayments();


      }

      this.loadcon = false;

    } catch (error) {
      console.error(error);

    this.loadcon = false;
  } 

    this.loading_person = false;


  }


  cancelPaymentQuestion = async (payment:any,msg = '') => {

    this.loadcon2 = true;
    try {
      
      const resp = await this._user.cancelPayment({ code_payment: payment,description:msg });


      const resp_paymentsCredits = await this.listPaymentsCredits();

      
      this.resp_paymentsCredits = resp_paymentsCredits?.res;

      
  
      if(resp?.res){
        this.getPayments();

        alertifyjs.success(`Pago anulado codigo ${payment}`);

      }
      this.loadcon2 = false;

    } catch (error) {
      console.error(error);

    this.loadcon2 = false;
  } 

 
    this.loading_person = false;


  }


  listPaymentsCredits = async () => {
       
    try {
      this.limit = 200; // Number of documents per page

      let body = this.filter

      if(body.type == 'p'){
        this.limit = 200; // Number of documents per page

      }

      let request = {
         page:this.page, 
         limit:this.limit,
         init:this.d1,
         end:this.d2
         }

        return this._user.listPaymentsCredits(body,request);

      } catch (error) {
        console.error(error);
        
        
      }
  }




  getIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

  }


  getContact(item: any) {

    if (!(item?.constacts?.length > 0))
      return "";

    return `${item?.constacts[0]?.contact}`;


  }


  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }


  redirectIMG(url:String){
    const link = `${url}`;
    window.open(link, '_blank');

}

setFilter(){

  this.page = 1;
  this.resp_paymentsCredits = [];
  this.getPayments();

}

getAccountPayment = async () =>  {
  
  this.accountPayment_load = true;

try {

 let resp =  await this._user.account_payment();
 this.accounts = resp?.res;

} catch (error) {
 console.log(error);

 
}

this.accountPayment_load = false;


}

nextRows = () =>{

  this.page = this.page + 1 ; 
  this.getPayments();

}

birthdaySet() {  

  this.resp_paymentsCredits =[];
  this.page =  1 ; 

  this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
  this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
  this.getPayments();
    
}

findAutopago = (acron: string) => {
  console.log(acron);
  if(acron==="BA") {
    let autopago = this.autopago.find((ele: any) => ele.key===acron);
    if(autopago) {
      return autopago.name;
    } else {
      return null;
    }
  } else {
    return acron
  }
}

}
