<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                Datos del Perfil
                                <span *ngIf="view == 2" class="text-dark"> (Direcciones)</span>
                                <span *ngIf="view == 3" class="text-dark"> (Condición Laboral)</span>
                                <span *ngIf="view == 4" class="text-dark"> (Ingreso Promedio)</span>
                                <span *ngIf="view == 5" class="text-dark"> (Medios de pago)</span>
                                <span *ngIf="view == 6" class="text-dark"> (Editar Medio de pago)</span>
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)=" view > 1 ? view !== 6?view = 1: view = 5 : offcanvas.dismiss('Cross click'); "></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-6 offset-md-3">

                    <app-data-profile [person]="person" [code_person]="person?.person?.code" *ngIf="view == 1" (changeView)="setChangView($event)"></app-data-profile>
                    <app-payment-method [code_person]="person?.person?.code" *ngIf="view == 5" (editView)="editPaymentMethod($event)" (changeView)="setChangView($event)"></app-payment-method>
                    <app-address [code_person]="person?.person?.code" *ngIf="view == 2" (changeView)="setChangView($event)"></app-address>
                    <app-working-condition [code_person]="person?.person?.code" *ngIf="view == 3" (changeView)="setChangView($event)"></app-working-condition>
                    <app-income [code_person]="person?.person?.code" *ngIf="view == 4" (changeView)="setChangView($event)"></app-income>
                    <app-payment-method-edit  *ngIf="view == 6" [dataEdit]="current_payment_method_id" (changeView)="setChangView($event)"></app-payment-method-edit>


                </div>
            </div>
        </div>
    </div>
</ng-template>


<div class="mt-md-3 p-3">

    <div class="row ">
        <div class=" col-12 col-md-8 offset-md-2">

            <h5 class="mb-1 text-secondary  mb-4 text-center"> <i class="  fas fa-user"></i> Onboarding
            </h5>


            <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && !person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                <img *ngIf="person?.person.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                <img *ngIf="person?.person.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">

            </div>

            <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                <img class="rounded-circle  " [src]="sanitize(imgPerson)">
            </div>



            <div *ngIf="!loading && person?.person" class="text-center mb-5">
                <div class="text-capitalize h4 text-dark">{{person?.person?.name}} {{person?.person?.lastName}}</div>
                <div> {{getIdentity(person)}}</div>
                <div> {{getContact(person)}}</div>
                
                <div class="pt-2" *ngIf="domiciliations.length > 0"><span class="fas fa-check-circle text-success  fz-20"></span> {{ 'Domiciliado '  }} <span class="font-weight-bold">{{ domiciliations[0].bank.name }}</span></div>
            </div>


            <ng-container *ngIf="!loading && person?.person" class="mt-2">

                <div class=" mt-4 list-group list-group-radio d-grid gap-2 border-0 w-auto" *ngIf="section_transaction">
                    <div *ngFor="let item of section_transactions" class="position-relative">

                        <label class="list-group-item py-3 " for="listGroupRadioGrid1">

                            <ng-container >

                               
                                <ng-container  *ngIf="section_transaction['TERMS_AND_CONDITIONS']?.condition ==  'COMPLETE' || item?.code == 'PRE_REGISTER' ">
                                    

                                    <span *ngIf="(item?.code == 'BURO' && !loading_buro && section_transaction[item?.code]?.condition ==  'COMPLETE') || (item?.code != 'BURO' && section_transaction[item?.code]?.condition ==  'COMPLETE')" class="fas fa-check-circle text-success float-end fz-25"></span>
                                    
                                     <span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' && item?.code == 'BURO' && !loading_buro" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="getDataBuro(person)">Consultar</button>
                                    </span>

                                    <span *ngIf="loading_buro && item?.code == 'BURO'" class="small text-dark float-end">Cargando...</span>

                                    <span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' && item?.code == 'PHOTO_IDENTITY'" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="setBiometria('PHOTO_IDENTITY')">Completar</button>
                                    </span>

                                  
                                    <span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' && item?.code == 'PROFILE_DATA'" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="pocisionBottom(content)">Completar</button>
                                        
                                    </span>

                                    <div  *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE' && item?.code == 'PROFILE_DATA'" class=" text-primary float-end me-2">
                                        
                                        <span (click)="pocisionBottom(content)"  class="text-secondary" > Editar</span>

                                    </div>

                                    <span *ngIf="section_transaction[item?.code]?.condition !=  'COMPLETE' &&  item?.code == 'SELFIE'" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="setBiometria('SELFIE')">
                                            <span > Completar</span>

                                        </button>
                                        
                                    </span>

                                
                                    <div   *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE'  &&  item?.code == 'SELFIE'"  class=" text-primary float-end me-2">

                                        <span (click)="setBiometria('SELFIE')" class="text-secondary" > Editar</span>

                                    </div>    

                                    <div *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE'  &&  item?.code == 'SELFIE'"  class=" text-primary float-end me-2">
                                        <span (click)="redirectIMG(person?.person?.file_selfie?.url)">Ver </span>

                                    </div>


                                    <div   *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE'  &&  item?.code == 'PHOTO_IDENTITY'"  class=" text-primary float-end me-2">

                                        <span (click)="setBiometria('PHOTO_IDENTITY')" class="text-secondary" > Editar</span>

                                    </div>    


                                    <div *ngIf="section_transaction[item?.code]?.condition ==  'COMPLETE' && item?.code == 'PHOTO_IDENTITY'" class=" text-primary float-end me-2">
                                        <span (click)="redirectIMG(person?.person?.file_identity?.url)">Ver </span>

                                    </div>


                                    <span *ngIf="item?.code == 'ANALYSIS'  && !loading_analysis && analysis_response " class="fas fa-check-circle text-success float-end fz-25"></span>
                                    
                                    <span *ngIf="item?.code == 'ANALYSIS'  && section_transaction[item?.code]?.condition != 'COMPLETE' && !loading_analysis && !analysis_response" class="small text-secondary float-end">
                                        <button type="button"  class=" btn btn-primary btn-sm" (click)="setAnalysis(person)">Consultar</button>
                                    </span>

                                    <span *ngIf="loading_analysis && item?.code == 'ANALYSIS'" class="small text-dark float-end">Cargando...</span>
                                
                              
                                </ng-container> 

                                <ng-container  *ngIf="section_transaction['TERMS_AND_CONDITIONS']?.condition !=  'COMPLETE' && section_transaction[item?.code]?.condition !=  'COMPLETE'">
                                    <span  class="small  float-end text-secondary" >Pendiente</span>
                                </ng-container>

                            </ng-container>
                            

                            <ng-container  *ngIf="section_transaction['TERMS_AND_CONDITIONS']?.condition ==  'COMPLETE'" ></ng-container>

                                   <div class="mb-2"><strong class="fw-semibold">{{item.name}}</strong></div> 
                                    <span class="d-block small opacity-75">{{item.description}}</span>
                                    <span *ngIf="!loading_buro && item?.code == 'BURO' && buro" class="small text-dark mt-1">
                                     Fecha   <span class="fw-bold">{{buro.fechaConsulta | date: 'dd/MM/yyyy h:mm:ss a'}} </span> 
                                      <!-- <span *ngIf="buro_score"> / Score </span>
                                      <span class="fw-bold"  *ngIf="buro_score">{{buro_score}}</span> -->
                                    </span>

                                    <span *ngIf="item?.code == 'TERMS_AND_CONDITIONS' && person?.person?.date_response_terms_and_conditions " class="small text-dark mt-1">
                                        Fecha de aprobación  <span class="fw-bold">  {{person?.person?.date_response_terms_and_conditions | date: 'dd/MM/yyyy'}}</span> 
                                         <!-- <span *ngIf="buro_score"> / Score </span>
                                         <span class="fw-bold"  *ngIf="buro_score">{{buro_score}}</span> -->
                                    </span>
                                    
                                    <div *ngIf="item?.code == 'ANALYSIS'  && !loading_analysis && analysis_response ">
                                        <div class="mt-3" >

                                            <span class="badge rounded-pill bg-secondary me-2 mt-2">Fecha : {{analysis_response?.date | date: 'dd/MM/yyyy h:mm:ss a' }}</span>
                                            <span class="badge rounded-pill bg-secondary me-2 mt-2">Estatus : {{analysis_response?.transaction_status}}</span>
                                            <span class="badge rounded-pill bg-secondary me-2 mt-2">Respuesta : {{analysis_response?.approve_status}}</span>
                                            <span class="badge rounded-pill bg-secondary me-2 mt-2">Consultado en : 

                                                <span *ngFor="let item of analysis_response?.approve_type_history;let i=index" >
                                                    
                                                    <span *ngIf="i > 0">, </span>
                                                    {{item}}
                                                </span>
                                            
                                            </span>

                                        </div>
                                    </div>
                         </label>

                         
                    </div>
                    
                  
                       
            <!--<pre>{{ analysis_response | json }}</pre>--> 
             

                    <div *ngIf="status_approved" class="position-relative">

                        <div class="list-group-item py-3 " for="listGroupRadioGrid1">

                            <div class=" text-secondary float-end text-end">
                                <!-- <div *ngIf="status_approved == 'REFUSED' " class=" text-danger ">Cliente no aplica</div>
                                <div *ngIf="status_approved == 'APPROVED' " class=" text-success ">Cliente aplica</div> -->

                                <ng-container *ngIf="(!start_psicometrico_load  && status_approved == 'WAIT_PSICOMETRICO' && !buro.start_psicometrico) || start_psicometrico ">

                                    <button type="button" *ngIf=" activatePsico " class=" btn btn-secondary btn-sm" (click)="setStatus_psicometrico(false)">Desactivar psicométrico </button>
                                    <button type="button" *ngIf=" !activatePsico " class=" btn btn-primary btn-sm" (click)="setStatus_psicometrico(true)">Activar psicométrico </button>

                                </ng-container>

                                <ng-container *ngIf="!start_psicometrico_load && status_approved == 'WAIT_PSICOMETRICO' && buro.start_psicometrico && !start_psicometrico">

                                    <div class="text-warning small"> En espera de resultado </div>
                                    <div class="text-secondary small">Activado {{buro.consult_psicometrico_date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                </ng-container>

                            </div>
                            <div>
                                <strong class="fw-bold" *ngIf="status_approved == 'WAIT_PSICOMETRICO'">Psicometrico</strong>
                                <span class="float-end text-primary cursor-pounter" *ngIf="status_approved != 'WAIT_PSICOMETRICO' && _auth.IsPermitid(['OWNER','ANALIST'])" (click)="send_responsea_approvated()">Renviar Msg</span>

                                <strong class="fw-semibold" *ngIf="!buro.start_psicometrico">Resultado</strong>


                                <!-- <div class=" small opacity-75 pt-2">Resultado del análisis </div> -->
                                <div class="text-dark small" *ngIf="buro.type_approved == 'PSICOMETRICO'"> <span class="fw-bold">Psicometrico <!-- ({{buro.psicometrico_score || 'No disponible por Buró'}})  --></span> {{buro.start_psicometrico_date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>
                                <div class="text-dark small" *ngIf="buro.type_approved == 'BURO'"><span class="fw-bold">Buro</span> {{buro.fechaConsulta | date: 'dd/MM/yyyy h:mm:ss a'}}</div>
                                <div class=" small  pt-2" *ngIf="buro.date_sms"><span class="fw-bold">Envio SMS</span> {{buro.date_sms | date: 'dd/MM/yyyy h:mm:ss a'}} </div>

                            </div>

                        </div>
                    </div>

                    <div *ngIf="status_approved == 'APPROVED'" class="position-relative mt-2">

                        <label style="    background: #FEFEFE; border: var(--bs-success) 1px solid;" class="list-group-item py-3 " for="listGroupRadioGrid1">
                            <span class=" text-secondary float-end">

                                <span  class=" text-dark fw-bold">{{person?.person?.quota | currency:'USD':'': '1.2-2'}} USD</span>
                            </span>
                            <strong class="fw-semibold">Cupo disponible</strong>
                            <br>

                            <span *ngIf="person?.person?.quota_active" class=" small text-success">Activo </span>
                            <span *ngIf="!person?.person?.quota_active" class=" small text-danger">Inactivo  </span>

                         </label>

                    </div>


                </div>


            </ng-container>


            <div *ngIf="!loading && !person?.person">
                <div>No se encontro person con este id: {{queryParams?.code}}</div>
            </div>

            <ng-container *ngIf="loading ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>

            <div *ngIf="loading_buro " class="text-center mt-2">
                <div>Consultando a buró...</div>
            </div>

        </div>
    </div>

</div>