<div class="mt-md-3 container-fluid">

    <div class="row ">
        <div class=" col-12 col-md-8 offset-md-2">

            <div class="row rmt-2" *ngIf="!loading_person">
                <div class="col-12  col-md-8 offset-md-2">

                    <h5 class="mb-1 text-secondary mb-4 text-center">

                        <i class="  fas fa-user"></i> Pagos realizados
                    </h5>
                </div>
            </div>

            <ng-container class="mt-4">
                <div class="row rmt-2">
                    <div class="col-12  col-md-10 offset-md-1">


                        <div class="row">

                            <div class="col-12 mt-2  mb-2  col-md-6">
                                <input style="
                                padding: 16px 9px;
                                background: white;
                            " id="demo-external" (onSet)="birthdaySet()" [(ngModel)]="range" mbsc-range class="form-control" [mbsc-options]="dateSetting3" #externalRange="mobiscroll" />

                            </div>
                        </div>
                        <div class="row">
                            <div class="mt-2 col-12 col-md-6 ">
                                <div class="form-floating ">
                                    <select (change)="setFilter()" [(ngModel)]="filter.type" class="form-select " id="floatingSelect " aria-label="Floating label select example ">
                            <option   value="all">Todos</option>
                            <option   value="p">Por conciliar</option>
                            <option   value="c">Conciliados</option>
                            <option   value="a">Anulados / Transacción fallida</option>
                            
                            </select>
                                    <label for="floatingSelect ">Estatus</label>
                                </div>

                            </div>



                            <div class="mb-4 mt-2 col-12 col-md-6 ">
                                <div class="form-floating ">
                                    <select (change)="setFilter()" [(ngModel)]="filter.account" class="form-select " id="floatingSelect " aria-label="Floating label select example ">
                                      <option   value="" >Todos</option>
                                      <option *ngFor="let item of accounts; let i=index"   [value]="item?.code" >

                                        <span *ngIf="item?.bank" >
                                            {{item?.bank?.name}} ({{item?.payment_method?.name}})
                                        </span>

                                        <span *ngIf="!item?.bank" >
                                            {{item?.payment_method?.name}}  ({{item?.currency?.code}})
                                        </span>
                                        
                                      
                                    </option>

                                    </select>
                                    <label for="floatingSelect ">Forma de pago</label>
                                </div>

                            </div>
                        </div>



                        <ng-container>

                            <div class=" w-100  mt-5 mb-2 flex " *ngIf="!loading_person &&  resp_paymentsCredits?.length > 0">
                                <div class="text-end ml-auto">

                                    {{resp_paymentsCredits?.length}}

                                </div>
                            </div>

                            <div class="bg-white mt-4 my-3 p-3 bg-body2  rounded shadow-sm" *ngFor="let item of resp_paymentsCredits">

                                <div class="row mt-2 row-k">

                                    <div class="col-8">
                                        <div class="d-flex" [routerLink]="['/console/person/financial-payments/',item?.payment?.owner?.code]">

                                            <div>
                                                <div *ngIf="item?.owner?.file_selfie">
                                                    <img style="width: 32px; height: 32px;" class="bd-placeholder-img flex-shrink-0 me-2 rounded " [src]="sanitize(item?.payment?.owner?.file_selfie?.url)">
                                                </div>

                                                <div *ngIf="!item?.owner?.file_selfie">
                                                    <img *ngIf="item?.payment?.owner.gender != 'F'" style="width: 32px; height: 32px;" class="bd-placeholder-img flex-shrink-0 me-2 rounded " [src]="sanitize('../assets/public/images/avatar.png')">
                                                    <img *ngIf="item?.payment?.owner.gender == 'F'" style="width: 32px; height: 32px;" class="bd-placeholder-img flex-shrink-0 me-2 rounded " [src]="sanitize('../assets/public/images/avatara-female.png')">
                                                </div>
                                            </div>
                                            <div>
                                                <strong class="text-gray-dark text-capitalize">{{item?.payment?.owner?.name}} {{item?.payment?.owner?.lastName}}</strong>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-4 mt-2  text-end">
                                        <div *ngIf="item?.payment?.file" [title]="item?.payment?.file?.url" class="text-primary fz-14" style="cursor:pointer">
                                            <span (click)="redirectIMG(item?.payment?.file?.url)">Ver Adjunto</span>
                                        </div>
                                    </div>

                                    <div class="col-8">
                                        <div class="small text-secondary">Código</div>
                                        <div class=" text-dark small"> {{item?.payment?.code}}</div>
                                    </div>

                                    <div class="col-4   ">

                                        <div class="text-end ms-auto">
                                            <div *ngIf="item?.payment?.typeTransaction !== 'DOMICILIATION'">
                                                <div class="small text-secondary">Conciliación</div>
                                                <div class=" small text-success" [ngClass]="{'text-success': item?.payment?.typeTransaction == 'AUTO','text-secondary': item?.payment?.typeTransaction != 'AUTO'}"> {{item?.payment?.typeTransaction == 'AUTO'? item?.payment?.responseAutoConfirm?.source?findAutopago(item?.payment?.responseAutoConfirm?.source):'Auto':'Manual'}}</div>
                                            </div>
                                            <div *ngIf="item?.payment?.typeTransaction === 'DOMICILIATION'">
                                                <div class="small text-secondary">Pago Domiciliado {{ item?.payment?.account_payment?.bank?.name }}</div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-6 ">
                                        <div class="small text-secondary">Monto total</div>
                                        <div class=" text-dark"> {{item?.payment?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                    </div>



                                    <div class="col-6 text-end">
                                        <div class="small text-secondary">Estatus</div>
                                        <!-- <div class="text-success" *ngIf="item?.payment?.reconciled_payment"><span class="badge text-bg-success">Aprobado</span> </div>

                                        <div *ngIf="!item?.payment?.reconciled_payment &&  item?.payment?.status"> <span class="badge text-bg-warning">Por conciliar</span>
                                        </div>
                                        <div *ngIf="!item?.payment?.status">
                                            <span class="badge text-bg-danger">{{item?.payment?.typeTransaction == 'AUTO'?'Transacción fallida': 'Anulado'}}</span>
                                        </div> -->
                                        <div class="text-success" *ngIf="item?.payment?.reconciled_payment">
                                            <div *ngIf="item?.payment?.typeTransaction!=='DOMICILIATION'">
                                                <span class="badge text-bg-success">Aprobado</span>
                                            </div>
                                            <div *ngIf="item?.payment?.typeTransaction==='DOMICILIATION'">
                                                <div *ngIf="item?.payment?.amount===0">
                                                    <span class="badge text-bg-danger">{{item?.payment?.description}}</span>
                                                </div>
                                                <div *ngIf="item?.payment?.amount>0">
                                                    <span class="badge text-bg-success">Aprobado</span>
                                                </div>
                                            </div>
                                        </div>
    
                                        <div *ngIf="!item?.payment?.reconciled_payment &&  item?.payment?.status">
                                            <span class="badge text-bg-warning">Por conciliar</span>
                                        </div>
                                        <div *ngIf="!item?.payment?.status">
    
                                            <div *ngIf="item?.payment?.typeTransaction!=='DOMICILIATION'">
                                                <span class="badge text-bg-danger">{{item?.payment?.typeTransaction == 'AUTO'?'Transacción fallida': 'Anulado'}}</span>
                                            </div>
                                            <div *ngIf="item?.payment?.typeTransaction==='DOMICILIATION'">
                                                <span class="badge text-bg-danger">Rechazado por el Banco</span>
                                            </div>
    
    
                                        </div>
                                    </div>

                                    <!-- 
                                <div class="col-12" *ngIf="item?.payment?.totalPaymentDue && item?.payment?.reconciled_payment">
                                    <div class="small text-danger">Monto Restante</div>
                                    <div class=" text-dark"> {{item?.payment?.totalPaymentDue | currency:'USD':'': '1.2-2'}} USD</div>
                                </div> -->

                                    <div class="col-6  ">
                                        <div class="small text-secondary">Moneda de la operación</div>
                                        <div class=" text-dark"> {{item?.payment?.currency?.name }}</div>
                                    </div>

                                    <div class="col-6 text-end">
                                        <div class="small text-secondary">Monto total <br class="d-block d-md-none">(moneda de la operación ) </div>
                                        <div class=" text-dark"> {{item?.payment?.counterValue | currency:'USD':'': '1.2-2'}} {{item?.payment?.currency?.name}}</div>
                                    </div>


                                    <div class="col-6">
                                        <div class="small text-secondary">Tasa de cambio <br class="d-block d-md-none"> a la fecha del pago</div>
                                        <div class=" text-dark"> USD 1 = BS {{item?.payment?.rate | currency:'USD':'': '1.2-2'}}</div>
                                    </div>

                                    <div class="col-6 text-end">
                                        <div class="small text-secondary ">Fecha reporte</div>
                                        <div class=" text-dark"> {{item?.payment?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                    </div>

                                    <div class="col-6 ">
                                        <div class="small text-secondary">Fecha de pago</div>
                                        <div class=" text-dark"> {{item?.payment?.register_payment_date | date: 'dd/MM/yyyy'}}</div>

                                    </div>

                                    <div class="col-6 text-end" *ngIf="item?.payment?.reconciled_payment">
                                        <div class="small text-secondary">Fecha conciliacion</div>
                                        <div class=" text-dark"> {{item?.payment?.reconciled_payment_date | date: 'dd/MM/yyyy'}}</div>

                                    </div>


                                    
                                    <div class="col-6  " [ngClass]="{'text-end': !item?.payment?.reconciled_payment}" *ngIf="item?.payment?.paymentMethodId && item?.payment?.status">
                                        <div class="small text-secondary">Número de confirmación</div>
                                        <div class=" text-dark"> {{item?.payment?.paymentMethodId }}</div>
                                    </div>


                                    <ng-container *ngIf="item?.payment?.account_payment">
                                        <div class="fw-bold text-secondary mt-3">Método de pago</div>

                                        <div class="titule-card-accion  fz-14 text-capitalize text-primary w-100 fw-bold">
                                            {{item?.payment?.account_payment?.bank?.name}}
                                        </div>

                                        <div class="d-flex mt-2">
                                            <div class="text-secondary  fz-12">
                                                Tipo
                                            </div>
                                            <div class="fw-bold fz-14 ms-auto">
                                                {{item?.payment?.account_payment?.payment_method?.name}}
                                            </div>
                                        </div>

                                        <div class="d-flex mt-1">
                                            <div class="text-secondary  fz-12">
                                                Moneda
                                            </div>
                                            <div class="fw-bold fz-14 ms-auto">
                                                {{item?.payment?.account_payment?.currency?.name}}
                                            </div>
                                        </div>
                                        <div class="d-flex mt-1" *ngIf="item?.payment?.account_payment?.taxId">
                                            <div class="text-secondary  fz-12">
                                                Rif
                                            </div>
                                            <div class="fw-bold fz-14 ms-auto">
                                                {{item?.payment?.account_payment?.taxId}}
                                            </div>
                                        </div>
                                        <div class="d-flex mt-1" *ngIf="item?.payment?.account_payment?.number">
                                            <div class="text-secondary  fz-12">
                                                Número
                                            </div>
                                            <div class="fw-bold fz-14 ms-auto">
                                                {{item?.payment?.account_payment?.number}}
                                            </div>
                                        </div>

                                        <div class="d-flex mt-1" *ngIf="item?.payment?.account_payment?.phone">
                                            <div class="text-secondary  fz-12">
                                                Teléfono
                                            </div>
                                            <div class="fw-bold fz-14 ms-auto">
                                                {{item?.payment?.account_payment?.phone}}
                                            </div>
                                        </div>

                                        <div class="d-flex mt-1" *ngIf="item?.payment?.account_payment?.email">
                                            <div class="text-secondary  fz-12">
                                                Email
                                            </div>
                                            <div class="fw-bold fz-14 ms-auto">
                                                {{item?.payment?.account_payment?.email}}
                                            </div>
                                        </div>

                                    </ng-container>

                                </div>

                                <div class="fw-bold text-secondary mt-3 mb-2">Voucher</div>


                                <div class="text-dark  fw-bold fz-12 mb-2" *ngIf="item?.payment?.responseAutoConfirm?.descripcion">
                                    {{item?.payment?.responseAutoConfirm?.descripcion}}
                                </div>

                                <div class="row" *ngIf="item?.payment?.responseAutoConfirm?.voucher">
                                    <div class="col-12 col-md-6">
                                        <div class="voucher p-2 text-dark" [innerHTML]="item?.payment?.responseAutoConfirm?.voucher">

                                        </div>

                                    </div>
                                </div>

                                <div class="row mt-3 row-k " *ngIf="item?.installMent?.length > 0">
                                    <div class="fw-bold text-secondary">Distribución</div>
                                    <ng-container class="mt-2" *ngFor=" let item2 of item?.installMent ;let i = index">


                                        <div class="col-6">
                                            <div class="small ">
                                                <span>
                                                 {{item2?.installMent?.invoice?.identifier}} / 
                                                {{ item2?.installMent?.order
                                                    < 1 ? 'Inicial': 'Cuota '+ item2?.installMent?.order }} 
                                                    <div class="small text-secondary small">{{item2?.installMent?.code }}</div>

                                            </span>

                                            </div>

                                            <div class="small text-secondary small">{{item?.payment?.installMent?.code }}</div>

                                        </div>
                                        <div class="col-6  text-end ">
                                            <div class=" text-dark"> {{item2?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                        </div>

                                    </ng-container>

                                </div>


                                <div class="row row-k" *ngIf="item?.paymen_categ_concept_deduc?.length > 0">

                                    <ng-container *ngFor=" let item3 of item?.paymen_categ_concept_deduc ;let i = index">
                                        <div class="col-8" *ngIf="item3?.category_concept_deduction?.concept_deduction?.name == 'IGTF'">
                                            <div class="small">{{item3?.category_concept_deduction?.concept_deduction?.name}}
                                                <span class="text-secondary" *ngIf="item3?.category_concept_deduction.isPorcent">{{item3?.category_concept_deduction?.amount}}%</span> </div>
                                        </div>
                                        <div class="col-4 text-end " *ngIf="item3?.category_concept_deduction?.concept_deduction?.name  == 'IGTF'">
                                            <div class=" text-dark"> {{item3?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                        </div>

                                    </ng-container>

                                </div>


                                <div *ngIf="!item?.payment?.reconciled_payment && item?.payment?.status" class="col-12 text-end mt-3">
                                    <button [disabled]="loadcon" type="button" (click)="concilidarPago(item?.payment?.code)" class="btn btn-primary mt-2 btn-sm">  
                                       Aprobar
                                    <span *ngIf="loadcon">...</span> </button>
                                    <button [disabled]="loadcon2" type="button" (click)="cancelPayment(item?.payment?.code)" class="btn btn-outline-danger  ms-2 mt-2 btn-sm">  
                                        Anular
                                <span *ngIf="loadcon2">...</span> </button>
                                </div>

                            </div>
                        </ng-container>


                        <div *ngIf="!loading_person &&  resp_paymentsCredits?.length > 0" class="mt-4 text-center">

                            <button type="button " class="btn btn-primary primary  " (click)="nextRows()">Siguientes + </button>

                        </div>


                        <!-- <pre>{{ resp_paymentsCredits | json }}</pre> -->


                    </div>
                </div>


            </ng-container>


            <ng-container *ngIf="loading_person">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>

            <div *ngIf="!loading_person && resp_paymentsCredits?.length < 1 " class="col-12  col-md-10 offset-md-1">
                <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm">
                    <div>No se encontraron pagos</div>
                </div>
            </div>

        </div>
    </div>

</div>