<div class="mt-md-3 container-fluid">

    <div class="row ">
        <div class=" col-12 col-md-8 offset-md-2">
            <div class="row rmt-2" *ngIf="!loading_person">
                <div class="col-12  col-md-8 offset-md-2">

                    <h5 class="mb-1 text-secondary mb-4 text-center">

                        <i class="  fas fa-user"></i> Pagos realizados
                    </h5>

                    <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && !person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                        <img *ngIf="person?.person.gender != 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatar.png')">
                        <img *ngIf="person?.person.gender == 'F'" class="rounded-circle  " [src]="sanitize('../assets/public/images/avatara-female.png')">

                    </div>


                    <div [routerLink]="['/console/person/profile/',person?.person?.code]" *ngIf="!loading && person?.person?.file_selfie" class="ms-auto me-auto mt-3 mb-3 bg-body rounded shadow-sm rounded-circle avatar">
                        <img class="rounded-circle  " [src]="sanitize(person?.person?.file_selfie?.url)">
                    </div>




                    <div *ngIf="!loading_person && person?.person" class="text-center mb-3">
                        <div class="text-capitalize h4 text-dark">{{person?.person?.name}} {{person?.person?.lastName}}</div>
                        <div> {{getIdentity(person)}}</div>
                        <div> {{getContact(person)}}</div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="!loading && person?.person" class="mt-2">
                <div class="row rmt-2">
                    <div class="col-12  col-md-8 offset-md-2">

                        <div class="bg-white mt-4 my-3 p-3 bg-body2  rounded shadow-sm" *ngFor="let item of resp_paymentsCredits">

                            <div class="row mt-2 row-k">

                                <div class="col-12 mt-2  text-end">
                                    <div *ngIf="item?.payment?.file" [title]="item?.payment?.file?.url" class="text-primary fz-14" style="cursor:pointer">
                                        <span (click)="redirectIMG(item?.payment?.file?.url)">Ver Adjunto</span>
                                    </div>
                                </div>
                                <div class="col-8   ">
                                    <div class="small text-secondary">Código</div>
                                    <div class=" text-dark small"> {{item?.payment?.code}}</div>
                                </div>

                                <div class="col-4   ">

                                    <div class="text-end ms-auto">
                                        <div *ngIf="item?.payment?.typeTransaction !== 'DOMICILIATION'">
                                            <div class="small text-secondary">Conciliación</div>
                                            <div class=" small text-success" [ngClass]="{'text-success': item?.payment?.typeTransaction == 'AUTO','text-secondary': item?.payment?.typeTransaction != 'AUTO'}"> {{item?.payment?.typeTransaction == 'AUTO'? item?.payment?.responseAutoConfirm?.source?findAutopago(item?.payment?.responseAutoConfirm?.source):'Auto':'Manual'}}</div>
                                        </div>
                                        <div *ngIf="item?.payment?.typeTransaction === 'DOMICILIATION'">
                                            <div class="small text-secondary">Pago Domiciliado {{ item?.payment?.account_payment?.bank?.name }}</div>
                                        </div>
                                    </div>
                                </div>


                                <div class="col-6 ">
                                    <div class="small text-secondary">Monto total</div>
                                    <div class=" text-dark"> {{item?.payment?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                </div>

                                <div class="col-6 text-end">
                                    <div class="small text-secondary">Estatus</div>

                                    <div class="text-success" *ngIf="item?.payment?.reconciled_payment">
                                        <div *ngIf="item?.payment?.typeTransaction!=='DOMICILIATION'">
                                            <span class="badge text-bg-success">Aprobado</span>
                                        </div>
                                        <div *ngIf="item?.payment?.typeTransaction==='DOMICILIATION'">
                                            <div *ngIf="item?.payment?.amount===0">
                                                <span class="badge text-bg-danger">{{item?.payment?.description}}</span>
                                            </div>
                                            <div *ngIf="item?.payment?.amount>0">
                                                <span class="badge text-bg-success">Aprobado</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div *ngIf="!item?.payment?.reconciled_payment &&  item?.payment?.status">
                                        <span class="badge text-bg-warning">Por conciliar</span>
                                    </div>

                                    <div *ngIf="!item?.payment?.status">

                                        <div *ngIf="item?.payment?.typeTransaction!=='DOMICILIATION'">
                                            <span class="badge text-bg-danger">{{item?.payment?.typeTransaction == 'AUTO'?'Transacción fallida': 'Anulado'}}</span>
                                        </div>
                                        <div *ngIf="item?.payment?.typeTransaction==='DOMICILIATION'">
                                            <span class="badge text-bg-danger">Rechazado por el Banco</span>
                                        </div>


                                    </div>

                                </div>
                                <div class="col-12" *ngIf="item?.payment?.automatic_direct_debit?.error">
                                    <div class="small text-secondary">Motivo de Rechazo</div>
                                    <div class=" text-dark">
                                        {{item?.payment?.automatic_direct_debit?.error?.description }}
                                    </div>
                                </div>

                                
                               

                                <div class="col-6  ">
                                    <div class="small text-secondary">Moneda de la operación</div>
                                    <div class=" text-dark"> {{item?.payment?.currency?.name }}</div>
                                </div>
                                




                                <div class="col-6 text-end">
                                    <div class="small text-secondary">Monto total <br class="d-block d-md-none">(moneda de la operación ) </div>
                                    <div class=" text-dark"> {{item?.payment?.counterValue | currency:'USD':'': '1.2-2'}} {{item?.payment?.currency?.name}}</div>
                                </div>


                                <div class="col-6">
                                    <div class="small text-secondary">Tasa de cambio <br class="d-block d-md-none"> a la fecha del pago</div>
                                    <div class=" text-dark"> USD 1 = BS {{item?.payment?.rate | currency:'USD':'': '1.2-2'}}</div>
                                </div>

                                <div class="col-6 text-end">
                                    <div class="small text-secondary ">Fecha reporte</div>
                                    <div class=" text-dark"> {{item?.payment?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</div>

                                </div>

                                <div class="col-6" >
                                    <div class="small text-secondary">Fecha de pago</div>
                                    <div class=" text-dark"> {{item?.payment?.register_payment_date | date: 'dd/MM/yyyy'}}</div>

                                </div>

                                <div class="col-6 text-end" *ngIf="item?.payment?.reconciled_payment && item?.payment?.status">
                                    <div class="small text-secondary">Fecha conciliacion</div>
                                    <div class=" text-dark"> {{item?.payment?.reconciled_payment_date | date: 'dd/MM/yyyy'}}</div>

                                </div>

                                <div class="col-6  " [ngClass]="{'text-end': !item?.payment?.reconciled_payment}" *ngIf="item?.payment?.paymentMethodId && item?.payment?.status">
                                    <div class="small text-secondary">Número de confirmación</div>
                                    <div class=" text-dark"> {{item?.payment?.paymentMethodId }}</div>
                                </div>


                                <ng-container *ngIf="item?.payment?.account_payment">

                                    <div class="titule-card-accion  fz-14 text-capitalize text-primary w-100 fw-bold">
                                        {{item?.payment?.account_payment?.bank?.name}}
                                    </div>

                                    <div class="d-flex mt-2" if>
                                        <div class="text-secondary  fz-12">
                                            Tipo
                                        </div>
                                        <div class="fw-bold fz-14 ms-auto">
                                            <div *ngIf="item?.payment?.automatic_direct_debit">
                                                Pago Domiciliado {{ item?.payment?.account_payment?.bank?.name }}                                            </div>
                                            <div *ngIf="!item?.payment?.automatic_direct_debit">
                                                {{item?.payment?.account_payment?.payment_method?.name}}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex mt-1" if>
                                        <div class="text-secondary  fz-12">
                                            Moneda
                                        </div>
                                        <div class="fw-bold fz-14 ms-auto">
                                            {{item?.payment?.account_payment?.currency?.name}}
                                        </div>
                                    </div>
                                    <div class="d-flex mt-1" *ngIf="item?.payment?.account_payment?.taxId">
                                        <div class="text-secondary  fz-12">
                                            Rif
                                        </div>
                                        <div class="fw-bold fz-14 ms-auto">
                                            {{item?.payment?.account_payment?.taxId}}
                                        </div>
                                    </div>
                                    <div class="d-flex mt-1" *ngIf="item?.payment?.account_payment?.number">
                                        <div class="text-secondary  fz-12">
                                            Número
                                        </div>
                                        <div class="fw-bold fz-14 ms-auto">
                                            {{item?.payment?.account_payment?.number}}
                                        </div>
                                    </div>

                                    <div class="d-flex mt-1" *ngIf="item?.payment?.account_payment?.phone">
                                        <div class="text-secondary  fz-12">
                                            Teléfono
                                        </div>
                                        <div class="fw-bold fz-14 ms-auto">
                                            {{item?.payment?.account_payment?.phone}}
                                        </div>
                                    </div>

                                    <div class="d-flex mt-1" *ngIf="item?.payment?.account_payment?.email">
                                        <div class="text-secondary  fz-12">
                                            Email
                                        </div>
                                        <div class="fw-bold fz-14 ms-auto">
                                            {{item?.payment?.account_payment?.email}}
                                        </div>
                                    </div>

                                </ng-container>


                            </div>

                            <div class="fw-bold text-secondary mt-2 mb-2" *ngIf="item?.payment?.status">Voucher</div>

                            <div class="text-dark  fw-bold fz-12 mb-2" *ngIf="item?.payment?.responseAutoConfirm?.descripcion">
                                {{item?.payment?.responseAutoConfirm?.descripcion}}
                            </div>

                            <div class="row" *ngIf="item?.payment?.responseAutoConfirm?.voucher">
                                <div class="col-12 col-md-6">
                                    <div class="voucher p-2 text-dark" [innerHTML]="item?.payment?.responseAutoConfirm?.voucher">

                                    </div>

                                </div>
                            </div>


                            <div class="row mt-3 row-k" *ngIf="item?.installMent?.length > 0">


                                <div class="fw-bold text-secondary">Distribución</div>
                                <ng-container class="mt-2" *ngFor=" let item2 of item?.installMent ;let i = index">


                                    <div class="col-6">
                                        <div class="small ">
                                            <span>
                                                 {{item2?.installMent?.invoice?.identifier}} / 
                                                {{ item2?.installMent?.order
                                                    < 1 ? 'Inicial': 'Cuota '+ item2?.installMent?.order }} 
                                                    <div class="small text-secondary small">{{item2?.installMent?.code }}</div>

                                            </span>

                                        </div>

                                        <div class="small text-secondary small">{{item?.payment?.installMent?.code }}</div>

                                    </div>
                                    <div class="col-6  text-end ">
                                        <div class=" text-dark"> {{item2?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                    </div>

                                </ng-container>

                            </div>

                            <div class="row row-k" *ngIf="item?.paymen_categ_concept_deduc?.length > 0">

                                <ng-container *ngFor=" let item3 of item?.paymen_categ_concept_deduc ;let i = index">
                                    <div class="col-8" *ngIf="item3?.category_concept_deduction?.concept_deduction?.name == 'IGTF'">
                                        <div class="small">{{item3?.category_concept_deduction?.concept_deduction?.name}}
                                            <span class="text-secondary" *ngIf="item3?.category_concept_deduction.isPorcent">{{item3?.category_concept_deduction?.amount}}%</span> </div>
                                    </div>
                                    <div class="col-4 text-end " *ngIf="item3?.category_concept_deduction?.concept_deduction?.name  == 'IGTF'">
                                        <div class=" text-dark"> {{item3?.amount | currency:'USD':'': '1.2-2'}} USD</div>
                                    </div>

                                </ng-container>

                            </div>

                            <div *ngIf="_auth.IsPermitid(['OWNER','ANALIST']) && !item?.payment?.reconciled_payment && item?.payment?.status" class="col-12 text-end mt-3">
                                <button [disabled]="loadcon" type="button" (click)="concilidarPago(item?.payment?.code)" class="btn btn-primary mt-2 btn-sm">  
                                    Aprobar
                                    <span *ngIf="loadcon">...</span> </button>
                                <button [disabled]="loadcon2" type="button" (click)="cancelPayment(item?.payment?.code)" class="btn btn-outline-danger  ms-2 mt-2 btn-sm">  
                                    Anular
                                <span *ngIf="loadcon2">...</span> </button>

                            </div>

                        </div>

                        <!-- <pre>{{ resp_paymentsCredits | json }}</pre> -->


                    </div>
                </div>


            </ng-container>


            <div *ngIf="!loading && !loading_person &&  person?.person && resp_paymentsCredits?.length < 1" class="col-12  col-md-8 offset-md-2">
                <div class="mt-5 my-3 p-3 bg-body rounded shadow-sm">
                    <div>No se encontraron pagos registrados</div>
                </div>
            </div>


            <div *ngIf="!loading_person && !person?.person">
                <div>No se encontro person con este id: {{queryParams?.code}}</div>
            </div>


            <ng-container *ngIf="loading_person ||  loading ">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif  " alt=" ">
                </div>
            </ng-container>
        </div>
    </div>

</div>