<div class="mt-md-3 p-3">


    <div class="row ">
        <div class=" col-12 col-md-6 offset-md-3 ">
            <h5 class="mb-4 text-secondary"> <i class="fas fa-tablet-alt"></i> Usuarios activos
            </h5>

            <div class="mt-2 mb-4">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a [ngClass]="{'active': rActive == 'CLIENT_ROLE'}" (click)="getRole('CLIENT_ROLE')" class="nav-link ">Clientes</a>
                    </li>
                    <li class="nav-item">
                        <a [ngClass]="{'active': rActive == 'USER_ROLE'}" (click)="getRole('USER_ROLE')" class="nav-link">Usuarios</a>
                    </li>
                    <li class="nav-item">
                        <a [ngClass]="{'active': rActive == 'COMPUTER_ROLE'}" (click)="getRole('COMPUTER_ROLE')" class="nav-link">Tablet</a>
                    </li>

                </ul>

            </div>

            <div *ngIf="isAuth && usersActivesRole?.length < 1" class="bg-white border border-1 text-success p-2 mb-5">
                <div class="text-secondary mb-2">No hay usuarios activos</div>

            </div>


            <div *ngIf="isAuth && usersActivesRole?.length > 0" class="mt-3 my-3 p-3 bg-body rounded shadow-sm">




                <h6 class="border-bottom pb-2 mb-0">Nombre</h6>

                <ng-container>

                    <div *ngFor="let item of usersActivesRole" class="d-flex text-muted pt-3">
                        <img style="width: 32px; height: 32px;" class="bd-placeholder-img flex-shrink-0 me-2 rounded " [src]="sanitize('../assets/public/images/avatar.png')">

                        <div *ngIf="rActive == 'CLIENT_ROLE'" [routerLink]="['/console/person/profile/',item?.person?.code]" class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <strong class="text-gray-dark"> {{item?.name }} {{ item?.lastName }} </strong>

                                <i class="  fas fa-circle text-success"></i>

                            </div>
                            <span class="d-block"> {{item?.username }}</span>
                        </div>

                        <div *ngIf="rActive != 'CLIENT_ROLE'" class="pb-3 mb-0 small lh-sm border-bottom w-100">
                            <div class="d-flex justify-content-between">
                                <strong class="text-gray-dark"> {{item?.name }} {{ item?.lastName }} </strong>

                                <i class="  fas fa-circle text-success"></i>

                            </div>
                            <span class="d-block"> {{item?.username }}</span>
                        </div>
                    </div>


                </ng-container>

                <!-- 
                <small class="d-block text-end mt-3">
                              <a href="#">All suggestions</a>
                            </small> -->
            </div>


        </div>
    </div>
</div>