export const environment = {
  production: true,
  BASE: 'akeela.co',
  BASE_LA: 'https://credit-api.akeela.co',
  BASE_FORNT2: 'https://credit-console.akeela.co',
  BASE_FORNT: 'https://credit-app.akeela.co',
  redirectRoute:"/redirect",
  searchClient:"/console/create-constacts",
  psicometrico:"/console/psicometrico",
  initRoute:"/console/search-client",

  externalPsicometrico:'https://www.acredita.com.ve:8860/psicometrico/home_test.php',

  loginRoute:"/login",
  logoutRoute:"/logout",

  socketBase:{
    SOCKET_ENDPOINT: 'https://credit-api.akeela.co/'
  },
  
  ClieID:"12",
  CED:"11314836",
  IP_LOCAL:"159.203.105.202"

};
