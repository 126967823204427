

import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { SocketioService } from '../../services/socket/socketio.service';
import { ConnectionService } from 'ng-connection-service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-list-online',
  templateUrl: './list-online.component.html',
  styleUrls: ['./list-online.component.css']
})
export class ListOnlineComponent implements OnInit {


 
  usersActives:any = [];
  usersActivesRole:any = [];

  usersMsg:any = [];
  isAuth:any;
  rActive="CLIENT_ROLE"
  
form:any  = {
  user:"",
  mensaje:"",
}

uid:any;

stadistic:any;

  constructor(
    public _auth : AuthService,
    public router: Router,
    private connectionService: ConnectionService,
    private offcanvasService: NgbOffcanvas,
    public socketService: SocketioService,
    private sanitizer: DomSanitizer,


 ) {
 }


  ngOnInit() {


    this.socketService.resetstadistic.subscribe((stadistic: any) => {

      this.stadistic = stadistic;

    });

  
    this.socketService.usersActives.subscribe((resp: any) => {
     // this.usersActives = resp.filter((item:any) => item.uid != this.uid);

     this.usersActives = [];

      resp?.map((item:any)=>{

        this.usersActives = [...this.usersActives,item];

      return item;

     });

     this.getRole(this.rActive);

    });

    this.socketService.usersMsg$.subscribe((rep: any) => {


      this.usersMsg = rep;


    });

    

    this._auth.auth$.subscribe((rep: any) => {

      if(rep){
        this.uid = this._auth.sessionUser?.uid;
        
       // this.usersActives =  this.usersActives.filter((item:any) => item.uid != this.uid);
      }
      this.isAuth = rep;

    });


  }
  
  

  getRole(role:any){

    this.rActive = role;
 let usersActivesRole:any =[];
 
    this.usersActives?.map((item:any)=>{

      if(item?.role?.role ==  role){

        usersActivesRole = [...usersActivesRole,item];

    }

    this.usersActivesRole = usersActivesRole;

    return item;

   });



  }

enviarMensaje(){

  this.socketService.mensajePrivado(this.form.user,this.form.mensaje);

  this.socketService.setUsersMsg( { de: `Tu`,mensaje: this.form.mensaje });

  this.form  = {
    user:"",
    mensaje:"",
  }

}


sanitize(url: string) {
  //return url;
  return this.sanitizer.bypassSecurityTrustUrl(url);
}


}

