import { Component, Input, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {
  public chart: any;

  @Input() config: any;
  @Input() mychart: any;


  constructor() { }

  ngOnInit(): void {
    this.createChart();
  }


  ngAfterViewInit() {
    this.createChart();
  }

    createChart(){
      
      this.chart = new Chart(this.mychart, this.config);
  }

  
}
