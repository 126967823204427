import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user/user.service';
import { mobiscroll, MbscCalendarOptions, MbscFormOptions, MbscDatetimeOptions } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import * as alertifyjs from 'alertifyjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';

mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light'
};

@Component({
  selector: 'app-search-client',
  templateUrl: './search-client.component.html',
  styleUrls: ['./search-client.component.css'],
  providers: [DatePipe],

})
export class SearchClientComponent implements OnInit {

  contact_phone_val_err:any;
  contact_email_val_err:any;
  @Output() close = new EventEmitter<any>();

  dateSetting: MbscCalendarOptions = {
    responsive: {
      small: {
          controls: ['calendar'],
          display: 'center'
      },
      medium: {
          display: 'bubble',
      }
  },
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light'
  };

  birthday:any;
  birthdayString:any ="";
  form:any;
  form2:any;
  form3:any;

  submit_disabled:any;
  person:any;
  searchPerson:any;
  step:any = 1;
  validateNumber:any;

  type_identity:any = [];
  code_identity:any = [];
  marital_status:any ;
  result:any;
  buro:any={};
  loading:any;

  submit_disabled_contact:any;
  validate_contact:any;

  activeValidateTlf = false;
  dataValidateTlf:any;
  email = "^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$";
  buro_loading:any;

  date_base: any = new Date;
  date_base_string :any;
  

  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom'
};

  mobileSettings: MbscDatetimeOptions = {
      display: 'bottom',
      themeVariant: 'dark',
  };
  

  desktopSettings: MbscDatetimeOptions = {
      display: 'bubble',
      touchUi: false
  };

  formSettings: MbscFormOptions = {
      inputStyle: 'box'
  };
  env = environment;
  show_data_buro:any;
  
  constructor(
    private formBuilder: FormBuilder,
    private _user:UserService,
    private datePipe: DatePipe,
    private router: Router,
    public _auth: AuthService


  ) { }

  ngOnInit(): void {
    
    this.date_base_string = this.datePipe.transform(this.date_base, 'MM/dd/yyyy');

    this._user.tlfValidates$.subscribe((response) => {

      // if(response[this.validate_contact]){

       if(response[this.dataValidateTlf]){
          this.loading = true;
           this.next2(true);
 
       }

    });
 

/*
    this._user.showValidateTel$.subscribe((response) => {
      if (response) {
          this.activeValidateTlf = true;
          this.dataValidateTlf = response;
      }
    });*/


    this.birthday =  new Date(`06/02/1988`);


    localStorage.removeItem('new_person');
    this.searchPerson =null;

    this.form = this.formBuilder.group({

      type_identity: ['CI',[Validators.required]],
      code_identity: ['V',[Validators.required]],
      identity: ['',[Validators.required,Validators.minLength(6),Validators.maxLength(8)],]
      
  });

  this.form2 = this.formBuilder.group({
    
    contact: ['',[Validators.required]],
    email: ['', [Validators.required,Validators.pattern(this.email)]]
  });




  this.form3 = this.formBuilder.group({
    name: ['',[Validators.required]],
    lastName: ['',[Validators.required]],
    second_name: [''],
    second_lastName: [''],
    marital_status: ['',[Validators.required]],
    gender: ['M',[Validators.required]]

  });

  
  this.getLists();
  
  }

  getLists = async () =>  {



    const [marital_status, type_identity,code_identity] = await Promise.all([
      await this._user.getPersonMaritalStatus(),
      await this._user.getPersonTypeIdentity(),
      await this._user.getPersonCodeIdentity()
  ]);

    this.marital_status = marital_status.resp;
    this.type_identity = type_identity.resp;
    this.code_identity = code_identity.resp;
  }

  submit = async () =>  {
    this.result=false;
    this.person = null;
    if ( this.form.valid) {
        this.submit_disabled = true;
        const data = this.form.getRawValue();

    try {
         
      let resp = await this._user.getPersonByIdentifier(data);
      this.searchPerson =data;
      this.result=true;

      if(resp.resp){

        this.person = resp.resp;

        
      }else{
        this.person = null;
        this.createPerson();
      }
      this.submit_disabled = false;

     } catch (error:any) {

        this.submit_disabled = false;
     }
  }
}


createPerson(){

  localStorage.setItem('new_person',JSON.stringify(this.searchPerson));
  this.step = 2;
  this.person = this.searchPerson;

}

next2 = async (validate:any = false) =>  {
  const data = this.form2.getRawValue();

  const  newPerson_json:any  = localStorage.getItem('new_person');
  const  newPerson = JSON.parse(newPerson_json);

  //ip-rest
  try {
       const params =  {
      "TypeID": newPerson?.code_identity,
      "DocID": newPerson?.identity,
      "IP":  environment.IP_LOCAL, 
    }

  
let contact = [
  { type_contact:"PHONE_CELL",
     verificated_contact:validate,
     contact: `+58${data?.contact}`
  },
  { type_contact:"EMAIL",
    verificated_contact:false,
    contact:data?.email
  }
]
 newPerson["contacts"] = contact;

  localStorage.setItem('new_person',JSON.stringify(newPerson));
  this.step = 3;
  this.person = newPerson;

  this.buro = null;
  this.loading = true ;
  this.show_data_buro =  false;

   let buro =  await this._user.getExistBuro(params);

 //let  buro =  {"res":{"Success":[{"DateOfBirth":"1990-09-21","Genere":"F","FirstGivenName":"VERONICA","MiddleName":"ANDREA","FirstSurName":"CARMAS","SecondSurname":"OLARTE"}]}}

  if(buro?.res?.Success){
    let data =  buro?.res.Success[0];
    this.buro =  buro?.res.Success[0];
    this.show_data_buro =  true;


    const base_date = data?.DateOfBirth;
    let  temp = base_date? base_date.split("-") : [];

   // this.birthday =   base_date ? new Date( `${temp[1]}/${temp[2]}/${temp[0]}` ) : new Date();

   this.date_base_string =  `${temp[1]}/${temp[2]}/${temp[0]}`;
   
    this.form3.patchValue({
      name:  data?.FirstGivenName?.toLowerCase(),
      lastName: data?.FirstSurName.toLowerCase(),
      second_lastName:  data?.SecondSurname ? data?.SecondSurname.toLowerCase(): '' ,
      gender: data?.Genere
     });

     this.date_base = new Date(this.date_base_string)

  }

  
  this.loading = false;

  } catch (error) {
    this.loading = false;
    console.error(error);

  }

  
}


  // simple handler without any parameters
  birthdaySet() {
    const date: any = this.datePipe.transform(this.birthday, 'dd/MM/yyyy');
    this.form3.patchValue({
      birth_date: date
   });
  }


  sendPerson = async () => {

          console.clear();
      if ( this.form.valid) {
          this.submit_disabled = true;
          const form = this.form3.getRawValue();
         let form_resp = Object.assign({}, form, this.person);
         form_resp = {
           ...form_resp,
         audience:"NATURAL",
         ejecutive:this._auth.sessionUser.person_code

        }

      //  

        form_resp.birth_date = this.jsDateToEpoch(this.date_base);
      
          try {
       
            let resp = await this._user.createPerson(form_resp);


          const  newPerson_json:any  = localStorage.getItem('new_person');
          const  newPerson = JSON.parse(newPerson_json);


  
            // let msg= `Hola ${form?.name} ${form?.lastName}. Acepta los terminos y condiciones ${environment.BASE_FORNT}/verification/approve-conditions/${resp?.resp?.code}`
           let msg= `Creditotal. Terminos y condiciones ${environment.BASE_FORNT}/verification/approve-conditions/${resp?.resp?.code}`

            let tlf = `${ this.dataValidateTlf}`;
        
            //this.sendNoteClient(msg,tlf);
        

            this.close.emit(true);

             this.router.navigate([`/console/person/profile/${resp?.resp?.code}`], {});


            alertifyjs.success('Creacion exitosa');
           //  this.router.navigate([environment.loginRoute], {});
             
           } catch (error:any) {
             
              alertifyjs.error('Error al crear el usuario');
              console.error(error);
              this.submit_disabled = false;
           }
      }

      
  }



getCodeBuroIdentity(code:any){
  let resp;
switch (code) {
  case "V":
    resp = 1;
    break;
    case "CP":
      resp = 2;
      break;
      case "E":
        resp = 3;
        break;
        case "P":
          resp = 4;
          break;

}

return resp;
}


 jsDateToEpoch = (d:any) => {

  // d = javascript date obj
  // returns epoch timestamp
  return (d.getTime())
}


  showValidateTel = async() =>{

    this.loading = true;
    let contact =  this.form2.getRawValue()?.contact;
    let email  =  this.form2.getRawValue()?.email;

    
   let dataValidateTlf = `+58${contact}`;

   this.contact_phone_val_err = false; 
   this.contact_email_val_err = false; 

   try {


    
   let contact_phone_resp = await this.getcontact(dataValidateTlf);
   let contact_phone_val = contact_phone_resp?.resp;


   if(contact_phone_val){
    this.contact_phone_val_err = true; 

   }else{
    this.contact_phone_val_err = false; 

   }


   let contact_email_resp = await this.getcontact(email);
   let contact_email_val = contact_email_resp?.resp;

  
   
   if(contact_email_val){
    this.contact_email_val_err = true; 

   }else{
    
    this.contact_email_val_err = false; 
// aqui va la prueba

   }

   if(!contact_phone_val && !contact_email_val){
   // contact = "+584241724412";
   this.step = 4;

   this.activeValidateTlf = true;
   this.dataValidateTlf = `+58${contact}`;

    this._user.setShowValidateTel(`+58${contact}`);

  }
  
  this.loading = false;

 } catch (error) {
   this.loading = false;

 }

      
  }



  showValidateTel2  = async () =>{
    this.loading = true;
    let contact =  this.form2.getRawValue()?.contact;
    let email  =  this.form2.getRawValue()?.email;

    
   this.dataValidateTlf = `+58${contact}`;

   this.contact_phone_val_err = false; 
   this.contact_email_val_err = false; 

   try {


    
   let contact_phone_resp = await this.getcontact(this.dataValidateTlf);
   let contact_phone_val = contact_phone_resp?.resp;


   if(contact_phone_val){
    this.contact_phone_val_err = true; 

   }else{
    this.contact_phone_val_err = false; 

   }


   let contact_email_resp = await this.getcontact(email);
   let contact_email_val = contact_email_resp?.resp;

  
   
   if(contact_email_val){
    this.contact_email_val_err = true; 

   }else{
    
    this.contact_email_val_err = false; 
// aqui va la prueba

   }

   if(!contact_phone_val && !contact_email_val){
    this.next2();
   }
   
   this.loading = false;

  } catch (error) {
    this.loading = false;

  }
  }



  getcontact = async (contact:any) => {
    
  
      try {
        const data =  {
          contact
          }
      
      return this._user.contact(data);
      
      
      } catch (error) {
        console.error(error);

    }
 }


 verificatedEmail = async (contact:any) => {
    
  
  try {
    const data =  {
      contact
      }
  
  return this._user.verificatedEmailPost(data);
  
  
  } catch (error) {
    console.error(error);

}
}


  closeValidateTlf = (e:any) => {

    this.activeValidateTlf = false;
    this.step = 2;


}

setValidateTlf = (e:any) => {
    let datas = this._user.tlfValidates$.getValue();

    datas[e] = true;

    this._user.setTlfValidates(datas);

}
  



  sendNoteClient = async (msg:any,phone:any) => {
    
    try {
      const data =  {
          phone,
          msg,
          "client": "CrediTotal"
        }
    
            let result = await this._user.sendsmsGeneral(data);
    
            if (!(result?.error)) {
    
    
            }
    
    } catch (error) {
      console.error(error);
      
      
    }
  
    



 }

  
dateSet(e: any) {

  let date_base =  e.valueText;
  
  let  temp = date_base? date_base.split("/") : [];
  this.date_base_string = `${temp[1]}/${temp[0]}/${temp[2]}`;
  




}



}
