
  import { DatePipe } from '@angular/common';
  import { noUndefined } from '@angular/compiler/src/util';
  import { Component, OnInit } from '@angular/core';
  import { async } from '@angular/core/testing';
  import { DomSanitizer } from '@angular/platform-browser';
  import { ActivatedRoute, Router } from '@angular/router';
  import { RedirecFormService } from 'src/app/services/redirec-form.service';
  import { UserService } from 'src/app/services/user/user.service';
  import { environment } from 'src/environments/environment';
  
  import * as alertifyjs from 'alertifyjs';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import Swal from'sweetalert2';
import { AuthService } from 'src/app/services/auth/auth.service';

  @Component({
    selector: 'app-person-payments',
    templateUrl: './person-payments.component.html',
    styleUrls: ['./person-payments.component.css'],
    providers: [DatePipe]
  
  })

export class PersonPaymentsComponent implements OnInit {
  
    queryParams: any;
    person: any;
    section_transactions: any = [];
    transaction: any = {};
    section_transaction: any;
    loading_person = false;
    loading = false;
  
    buro_response: any;
    buro: any;
    buro_score: any;
    activatePsico: any;
    showActivatePsico: any;
  
    loading_buro = false;
    psicometrico: any;
    psicometrico_response: any;
    psicometrico_array: any = [];
    status_approved: any;
     type_approved: any;
     clientQuota:any;
     invoiceCredits: any = [];
     resp_paymentsCredits: any = [];
     installments: any = [];

     autopago: any = [{key: "BA", name: "AUTO: BANCAAMIGA"}, {key: "MERCHANT", name: "AUTO: MERCHANT"}];


  view = 1;
  invoiceSelected:any;
  installMentSelected:any;
  payment:any;
  loadcon:any;
  loadcon2:any;
  
    constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private _user: UserService,
      private sanitizer: DomSanitizer,
      private redirecFormService: RedirecFormService,
      private datePipe: DatePipe,
      public socketService: SocketioService,
      public _auth : AuthService

  
    ) { }
  
    ngOnInit(): void {
  
      this.activatedRoute.params.subscribe(params => {
        this.queryParams = params;
        if (this.queryParams?.code) {
  
          this.getPerson();
        }
  
      });
  
    }
  



    cancelPayment = async (payment:any) => {


      let action = 'Anular'
  
  
      Swal.fire({
        title: "¿Deseas Anular este pago?",
        text: "¡No podrás revertir esto!",
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        input: "textarea",
        inputAttributes: {
          autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: `Si, ${action}!`,
        showLoaderOnConfirm: true,
        preConfirm: async (login) => {
          try {
          
            if (login?.trim() == '') {
              return Swal.showValidationMessage(`Debe añadir una razón de anulación`);
            }
  
          } catch (error) {
            Swal.showValidationMessage(`
              Request failed: ${error}
            `);
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
  
           this.cancelPaymentQuestion(payment,result?.value);
  
        }
      });
  
    }
  

    cancelPaymentQuestion = async (payment:any,msg = '') => {

      this.loadcon2 = true;
      try {
        
        debugger
        const resp = await this._user.cancelPayment({ code_payment: payment,description:msg });
        this.socketService.setResetstadistic(true);
  
        const resp_paymentsCredits = await this.listClientPaymentsCredits();
        this.resp_paymentsCredits = resp_paymentsCredits?.res;
  
   
        if(resp?.res){
  
          alertifyjs.success(`Pago anulado codigo ${payment}`);
          this.getPerson();

        }
        
        this.loadcon2 = false;
  
      } catch (error) {
        console.error(error);
  
      this.loadcon2 = false;
    } 
  
   
      this.loading_person = false;
  
  
    }


    getPerson = async () => {
      this.loading_person = true;
      try {
  
        const resp = await this._user.getPersonbyCode({ code: this.queryParams.code });
        this.person = resp.resp;

        const resp_paymentsCredits = await this.listClientPaymentsCredits();
        this.resp_paymentsCredits = resp_paymentsCredits?.res;

  
      } catch (error) {
        console.error(error);
  
      } 
  
   
      this.loading_person = false;

  
    }


    concilidarPago = async (payment:any) => {
      let action = 'Conciliar'
  
      Swal.fire({
        title: `¿Deseas ${action} este pago?`,
        text: "¡No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: `Si, ${action}!`
      }).then((result) => {
        if (result.isConfirmed) {
          
  
          this.concilidarPagoQuestion(payment);
  
        }
      });
  
    }



   concilidarPagoQuestion = async (payment:any) => {
    this.loadcon = true;
    try {
      const resp = await this._user.reconciledPayment({ code_payment: payment });

      const resp_paymentsCredits = await this.listClientPaymentsCredits();
      this.resp_paymentsCredits = resp_paymentsCredits?.res;
  
  
      if(resp?.res){


        this.getPerson();


      }

      this.loadcon = false;

    } catch (error) {
      console.error(error);

    this.loadcon = false;
  } 

    this.loading_person = false;


  }




  
    listClientPaymentsCredits = async () => {
         
      try {
           const data =  {
            code_person :this.queryParams?.code
           
           }
  
          return this._user.listClientPaymentsCredits(data);
  
        } catch (error) {
          console.error(error);
          
          
        }
    }
  
  
  
  
    getIdentity(item: any) {
  
      if (!(item?.identitys?.length > 0))
        return "";
  
      return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;
  
    }
  
  
    getContact(item: any) {
  
      if (!(item?.constacts?.length > 0))
        return "";
  
      return `${item?.constacts[0]?.contact}`;
  
  
    }
  
  
    sanitize(url: string) {
      //return url;
      return this.sanitizer.bypassSecurityTrustUrl(url);
    }
  

    redirectIMG(url:String){
      const link = `${url}`;
      window.open(link, '_blank');

  }

  findAutopago = (acron: string) => {
    console.log(acron);
    if(acron==="BA") {
      let autopago = this.autopago.find((ele: any) => ele.key===acron);
      if(autopago) {
        return autopago.name;
      } else {
        return null;
      }
    } else {
      return acron
    }
  }
  
  
  }
  