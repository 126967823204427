import { CommonModule } from '@angular/common';
import {
  Component,
  forwardRef,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';
import { AuthService } from 'src/app/services/auth/auth.service';

import {
  mobiscroll,
  MbscDatetimeOptions,
  MbscFormOptions,
} from '@mobiscroll/angular';
@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.css'],
})
export class PaymentMethodComponent implements OnInit {
  @Input() code_person: any;
  @Output() changeView = new EventEmitter<any>();
  @Output() editView = new EventEmitter<any>();
  @ViewChild('miInput') miInput: ElementRef | undefined;


  @ViewChild('miInput') miInputVariable: ElementRef | undefined;
  mobileSettingsFile: MbscDatetimeOptions = {
    display: 'bottom',
  };

  form: any;
  submit_disabled: any;

  country: any;
  city: any;
  state: any;
  type: any;

  errorMsg: any;
  loadForm: any;
  loadPerson_Address: any;
  section_transactions: any = [];
  accounts: any = [];
  section_complete: any;
  validation_account_number: any;
  banks: any = [];
  bank: any;

  formDataUpload: any = null;
  fileName = '';
  value = '';
  last_value = this.value;

  sessionUser: any;


 

  constructor(
    private _common: CommonService,
    private formBuilder: FormBuilder,
    private _user: UserService,
    public _auth: AuthService
  ) {}

  ngOnInit(): void {
    this.sessionUser = this._auth.sessionUser;
    this.form = this.formBuilder.group({
      bank: ['', [Validators.required]],
      currency: ['63ffb65dbb023a686c40ab8c', [Validators.required]],
      payment_method: ['640b83e26522a425711e006f', [Validators.required]],
      account_type: ['', [Validators.required]],
      account_number: ['', [Validators.required, Validators.minLength(20)]],
    });

    this.getAccounts();
    this.getBanks();
  }

  ngAfterViewInit() {
    if(this.miInput) {
      this.miInput.nativeElement.onpaste = function(e: any) {
        e.preventDefault();
      };
      this.miInput.nativeElement.oncopy = function(e: any) {
        e.preventDefault();
      };
    }
  }

  onChange = ($event: any) => {
    let target = $event.target as HTMLInputElement;
    let value = target.value;

    if (value) {
      if (value && value.length >= 4) {
        this.value = value;
        this.last_value = this.value;
      } else {
        this.value = this.last_value;
      }
    } else {
      this.value = this.last_value;
    }

    this.form.patchValue({
      account_number: this.value,
    });
  };

  reset() {
    this.formDataUpload = null;
    this.fileName = '';
  }

  onFileSelected = async (event: any) => {
    const file: File = event.target.files[0];
    if (file) {
      this.fileName = file.name;
      this.formDataUpload = file;
    }
  };

  setBank = async () => {
    try {
      const data = this.form.getRawValue();

      let banks_resp = this.banks?.filter((item: any) => {
        return item.code == data.bank;
      });

      this.bank = banks_resp[0];
      this.value = this.bank.bankCode;
      this.last_value = this.value;
      this.form.patchValue({
        account_number: this.bank.bankCode,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getBanks = async () => {
    try {
      const resp = await this.getBanksServer();
      this.banks = resp.res.filter((item: any) => {
        console.log(item.showClient)
        return item.bankCode ? item.showClient=== true ? true : false : false;
      });
      this.setBank();
    } catch (error) {
      console.error(error);
    }
  };

  getBanksServer = async () => {
    try {
      return this._user.getBanks({});
    } catch (error) {
      console.error(error);
    }
  };

  getLists = async () => {
    try {
      this.loadForm = true;
      const [country, city, state, type] = await Promise.all([
        await this._common.getCountry({}),
        await this._common.getCity({}),
        await this._common.getState({}),
        await this._common.getTypeAddress({}),
      ]);

      this.country = country.resp;
      this.city = city.resp;
      this.state = state.resp;
      this.type = type.resp;
      this.loadForm = false;
    } catch (error) {
      console.log(error);
    }
  };

  getAccounts = async () => {
    try {
      this.loadPerson_Address = true;
      let resp = await this._common.getPayment_method({
        code_person: this.code_person,
      });

      this.accounts = resp?.resp;
      this.loadPerson_Address = false;
    } catch (error) {
      console.log(error);
      this.loadPerson_Address = false;
    }
  };

  edit_account = async (payment_method_customer: any) => {
    this.editView.emit({
      view: 6,
      id: payment_method_customer,
    });
  };

  delete_account = async (payment_method_customer: any) => {
    debugger;
    let params = {
      payment_method_customer: payment_method_customer,
    };

    try {
      let resp = await this._common.delete_payment_method(params);

      this.getAccounts();

      this.submit_disabled = false;
    } catch (error: any) {
      const params = error?.error?.error;
      if (params?.code) {
        this.errorMsg = params?.msg;
      }

      this.submit_disabled = false;
    }
  };

  submit = async () => {
    const data = this.form.getRawValue();
    console.log(data.account_number);
    console.log(this.bank.bankCode);
    if (this.form.valid) {
      if(data.account_number.toString().substring(0,  4) === this.bank.bankCode){
        this.submit_disabled = true;
        

        let params = {
          code_person: this.code_person,
          code_bank: data.bank,
          currency: data.currency,
          payment_method: data.payment_method,
          account_type: data.account_type,
          account_number: data.account_number,
          user: this.sessionUser.uid
        };

        const formData = new FormData();

        if (this.formDataUpload) {
          formData.append('archivo', this.formDataUpload);
        }

        formData.append('params', JSON.stringify(params));

        try {
          let resp = await this._common.setPayment_method(formData);
          // let resp = await this._common.setPayment_method(params);
          alertifyjs.success('Registro exitoso');

          this.form = this.formBuilder.group({
            bank: ['', [Validators.required]],
            currency: ['63ffb65dbb023a686c40ab8c', [Validators.required]],
            payment_method: ['640b83e26522a425711e006f', [Validators.required]],
            account_type: ['01', [Validators.required]],
            account_number: ['', [Validators.required]],
          });

          this.reset();

          this.getAccounts();

          this.setBank();

          this.submit_disabled = false;
        } catch (error: any) {
          const params = error?.error;
          if (params) {
            this.errorMsg = params?.msg;
          }
          alertifyjs.error(this.errorMsg);
          this.submit_disabled = false;
        }
      } else {
        
        this.form.patchValue({
          account_number: this.bank.bankCode,
        });
        this.submit_disabled = false;
        alertifyjs.error('Los primeros 4 dígitos del número de cuenta no son válidos para una cuenta del banco seleccionado.');
      }
    }
  };

  setChange(View: any) {
    this.changeView.emit(View);
  }

  validate_account_number = () => {
    let campo = this.form.controls.account_number.value;

    if (campo.length == 20) {
      this.validation_account_number = true;
    } else {
      this.validation_account_number = false;
    }
  };
}
