<div class="container  pe-2">

    <div class=" mt-3 mb-3">

        <div *ngIf="isAuth && usersActives?.length > 0" class=" col-md-8 offset-md-2 border border-1 text-success p-2 mb-5">
            <h5 class="text-secondary mb-2">Usuarios activos</h5>
            <ng-container *ngFor="let item of usersActives">
                <div class="mb-2 mt-2 ">
                    {{item?.nombre }} {{item?.apellido }}
                </div>
            </ng-container>
        </div>

        <div *ngIf="isAuth && usersActives?.length < 1" class=" col-md-8 offset-md-2 border border-1 text-success p-2 mb-5">
            <h5 class="text-secondary mb-2">No hay usuarios activos</h5>

        </div>


        <div *ngIf="isAuth && usersActives?.length > 0" class="col-md-8 offset-md-2 mt-3 pb-3 ">
            <div class="form-group mt-4 ">

                <div class="form-floating ">
                    <select [(ngModel)]="form.user " class="form-select " id="floatingSelect " aria-label="Floating label select example ">
                      <option   value="">Seleccione</option>
                      <ng-container *ngFor="let item of usersActives ">
                        <option  [value]="item?.uid " > {{item?.name }} {{item?.lastName }}</option>
                      </ng-container>
                      
                    </select>
                    <label for="floatingSelect ">Usuarios activos</label>
                </div>
            </div>

            <div class="form-group mt-4 ">
                <div class="form-floating ">
                    <textarea [(ngModel)]="form.mensaje " class="form-control " placeholder="Leave a comment here " id="floatingTextarea2 " style="height: 100px "></textarea>
                    <label for="floatingTextarea2 ">Descripción</label>
                </div>
            </div>
            <div class="form-group mt-4 ">

                <button type="button " class="ms-2 me-2 btn btn-success " (click)="enviarMensaje() ">
                               
                
                    <span  >
                        Enviar 
                    </span>
                
                </button>
            </div>

        </div>

        <div *ngIf="isAuth && usersMsg?.length> 0" class=" col-md-8 offset-md-2 border border-1 p-2 mt-4">
            <h5 class="text-secondary mb-2">Mensajes</h5>
            <ng-container *ngFor="let item of usersMsg">
                <div class="mb-2 mt-2 ">
                    <span [ngClass]="{'ms-2': item?.de != 'Tu','ms-2 text-success': item?.de == 'Tu'}" class="fw-bold">{{item?.de }}  </span> {{item?.mensaje }}
                </div>
            </ng-container>
        </div>


    </div>

</div>