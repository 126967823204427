<div class="container">


    <div class="mt-md-3 p-3">

        <h5 class="mb-1 text-secondary  mb-4 text-center"> Estadísticas
        </h5>
        <div class="row ">
            <div class=" col-12 col-md-6 offset-md-3  p-0 ">
                <div class="mt-5 ">
                    <div class="col-12  mt-4" *ngIf="_auth.IsPermitid(['OWNER'])">
                        <div class="card" [routerLink]="['/console/stadistic']">
                            <div class="card-body">
                                <h5 class="card-title">Compras/Pagos
                                    <i class="float-end pt-2  fas fa-piggy-bank"></i>

                                </h5>

                            </div>
                        </div>
                    </div>

                    <div class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/stadistic_approved']">
                            <div class="card-body">
                                <h5 class="card-title"> Estatus de aprobación Compras/Pagos
                                    <i class="float-end pt-2  fas fa-tablet-alt"></i>

                                </h5>

                            </div>
                        </div>
                    </div>

                    <div class="col-12  mt-4">
                        <div class="card" [routerLink]="['/console/estadisticas-ejecutivos']">
                            <div class="card-body">
                                <h5 class="card-title"> Ejecutivos
                                    <i class="float-end pt-2  fas fa-user"></i>

                                </h5>

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>