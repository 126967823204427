import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SocketioService } from '../socket/socketio.service';


@Injectable({
  providedIn: 'root',
})
export class DirectDebitService {
  private url_la: any;
  private env: any = environment;

  constructor(
    public httpClient: HttpClient,
  ) {
    this.url_la = this.env.BASE_LA;

    if(localStorage.getItem('MARKA') == 'public-0ak12haz43' && this.env.production ){

      this.url_la = `https://inst1.${this.env.BASE}`;

    }

    if(localStorage.getItem('MARKA') == 'public-0ak12haz52' && this.env.production ){

      this.url_la = `https://inst2.${this.env.BASE}`;

    }
  }

  get baseUrl() {
    return this.url_la;
  }

  /* Retorna la información base de un proceso de domiciliación del día, para el banco y el tipo */
  getDirectDebitAlly = async (ally: string, type: string, date: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {
      ally,
      type,
      date
    };
    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/direct_debit_alliances', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  /* Retorna la información base de un proceso de domiciliación del día, para el banco y el tipo */
  getDirectDebit = async (bank: string, type: string, date: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {
      bank,
      type,
      date
    };
    try {
      return lastValueFrom(
        this.httpClient.get<any>(this.baseUrl + '/api/direct_debit', {
          headers: headers,
          params: request,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  /* Retorna toda la traza con la información por status de un proceso de domiciliación en base al id de direct_debit */
  getDirectDebitStatuses = async (direct_debit_id: string): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {
      direct_debit_id,
    };
    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/direct_debit/direct_debit_status',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };


  

  cancel_direct_debit = async (body: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/api/direct_debit/direct_debit_cancel`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  
  update_rate_direct_debit = async (body: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/api/direct_debit_alliances/direct_debit_rate`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  finish_direct_debit = async (body: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/api/direct_debit_alliances/direct_debit_finish`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  /* servicio que invoca al servicio que procesa y genera el txt que se envia al banco para la domiciliación de cuentas */
  startDirectDebitProcess = async (body: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/direct_debit/start`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  startDirectDebitAllianceProcess = async (body: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/direct_debit_alliances/start`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  pendingDirectDebit = async (body: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/direct_debit/pending_domiciliation`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  saveDirectDebitStatus = async (body: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/direct_debit/save_direct_debit_status`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  conciliarAlianza = async (body: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/direct_debit_alliances/direct_debit_conciliation`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  conciliar = async (body: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/direct_debit/direct_debit_conciliation`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  pendingLote = async (): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = { };
    try {
      return lastValueFrom(
        this.httpClient.get<any>(
          this.baseUrl + '/api/direct_debit/pending_lote',
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  pendingLoteGeneric = async (body: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/direct_debit_alliances/pending_lote`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }


    
  };

  historic = async (body: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/direct_debit/history`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };


  generarReporte = async (body: any): Promise<any> => {
    const headers = { 'content-type': 'application/json' };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.post<any>(
          `${this.baseUrl}/api/direct_debit/generate_excel`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  readDirectDebitFile = async (config: any, file: any): Promise<any> => {
    const headers = { 'content-type': 'multipart/form-data' };
    const form = new FormData();
    form.append('config', config);
    form.append('archivo', file);
    try {
     return lastValueFrom(
       this.httpClient.post<any>(
         `${this.baseUrl}/api/direct_debit/read_response_bank`,
         form,
         { headers: {} , params: {} }
       )
     );
    } catch (error) {
      
      console.error(error);
      return false;
    }
   };

   readDirectDebitFileXlsx = async (file: any): Promise<any> => {
    const headers = { 'content-type': 'multipart/form-data' };
    const form = new FormData();
    form.append('file', file);
    try {
     return lastValueFrom(
       this.httpClient.post<any>(
         `${this.baseUrl}/api/direct_debit_alliances/direct_debit_read_response`,
         form,
         { headers: {} , params: {} }
       )
     );
    } catch (error) {
      
      console.error(error);
      return false;
    }
   };
  


  downloadFile = (url: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.httpClient.get(url, { responseType: 'blob' }).subscribe(res => {
        const blob = new Blob([res], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);
        resolve(url);
      }, error => {
        resolve(null);
      });
    });
  }

  updatePaymentMethodCustomer = async (body: any, id: any): Promise<any> => {
    const headers = { };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/api/payment_method/customer?id=`+id,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  verifyAccounts = async (body: any): Promise<any> => {
    const headers = { };
    const request = {};
    try {
      return lastValueFrom(
        this.httpClient.put<any>(
          `${this.baseUrl}/api/payment_method/verify_accounts`,
          body,
          { headers: headers, params: request }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  downloadImage(publicId: string, extension:string) {
    try{
    return lastValueFrom(
      this.httpClient.get(`${this.baseUrl}/api/direct_debit/download?public_id=${publicId}&ext=${extension}`, { responseType: 'blob' })
      );
    } catch (error) {
      console.error(error);
      return null;
    }
  }

}
