<div class="mt-md-3 ms-auto me-auto p-3 p-md-auto">
    <div class="  box pt-2 pb-2 p-1  pt-md-4 pb-md-4 p-3 fz-14 ">
      
        
        <h5 class="mb-4">Iniciar sesión
            <span (click)="reloadLS()"  class="text-primary ms-2">
                <i class="fas fa-tablet-alt"></i>
            </span>
        </h5>
       
        <div *ngIf="errors?.length > 0" class="mb-4 text-start p-3 box-errors">
            <ng-container *ngFor="let item of errors">

                <div class="d-inline-flex w-10">
                </div>
                <div class="d-inline-flex w-90">
                    <i class="fas fa-info-circle pe-2 "></i>

                    <span>{{item?.msg }}  </span>

                </div>
            </ng-container>
        </div>

        <form novalidate [formGroup]="form" (ngSubmit)="submit()" class="text-start">
            <div class="row">

                <div class="form-group col-12  mb-3">

                    <div class="form-floating ">
                        <input type="text" [ngClass]="{'error': form.controls.correo.invalid && form.controls.correo.dirty}" class="form-control" id="correo" formControlName="correo" placeholder="name@example.com">
                        <label for="correo" class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span >Username</span>
                          </label>
                    </div>


                    <div class="form-control-feedback help-block" *ngIf="form.get('correo').errors">
                        <div *ngIf="form.get('correo').errors['required'] && !form.get('correo').pristine">
                            Campo requerido
                        </div>
                    </div>
                </div>


                <div class="form-group col-12 mb-3">

                    <div class="form-floating ">
                        <input type="password" [ngClass]="{'error': form.controls.password.invalid && form.controls.password.dirty}" class="form-control" id="password" formControlName="password" placeholder="name@example.com">
                        <label for="password" class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span >Password</span>
                          </label>
                    </div>

                    <div class="form-control-feedback help-block" *ngIf="form.get('password').errors">
                        <div *ngIf="form.get('password').errors['required'] && !form.get('password').pristine">
                            Campo requerido
                        </div>
                        <div *ngIf="form.get('password').hasError('minlength')  && !form.get('password').pristine">
                            Mínimo {{passwordMinLength}} caracteres
                        </div>

                        <div *ngIf="form.get('password').hasError('maxlength')  && !form.get('password').pristine">
                            Máximo {{passwordMaxLength}} caracteres
                        </div>

                    </div>
                </div>

                <br>
                <div class=" col-12 mt-2 mb-2">
                    <button type="submit" [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary ">
                                                    <span >Continuar</span>
                                                    <span *ngIf="submit_disabled">
                                                      <i class="fa  fa-spinner fa-spin ms-2"></i>
                                                    </span>
                                                  </button>
                </div>

            </div>
        </form>

    </div>
</div>