import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-dnd',
  templateUrl: './progress-dnd.component.html',
  styleUrls: ['./progress-dnd.component.css']
})
export class ProgressDndComponent implements OnInit {

  @Input() progress = 0;
  
  constructor() {}

  ngOnInit() {}

}
