import { DatePipe } from '@angular/common';
import { noUndefined } from '@angular/compiler/src/util';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import { isNullOrUndefined } from 'util';
import { ApproveConditionsComponent } from '../approve-conditions/approve-conditions.component';
import * as alertifyjs from 'alertifyjs';

@Component({
  selector: 'app-contact-profile',
  templateUrl: './contact-profile.component.html',
  styleUrls: ['./contact-profile.component.css'],
  providers: [DatePipe]

})
export class ContactProfileComponent implements OnInit {
  usQuote: any = false;
  code_person:any;

  supUse:any;
  queryParams: any;
  person: any;
  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  loading = false;
  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
   type_approved: any;
   clientQuota:any;
   clientAprov:any;
   clientAprovBuro:any;
   searchPerson:any;
   countInvoice:any;

   identityResponse:any;
   result:any;
   errorMsg:any;
   loadQuota = false;
   env = environment;
   base_amount:any;
   usQuoteClien = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    private offcanvasService: NgbOffcanvas,
    public _auth: AuthService,



  ) { }

  ngOnInit(): void {

    this.getBase_amount();

    this._auth.auth$.subscribe((rep: any) => {

      let sessionUser = rep? this._auth.sessionUser: null; 
      
      if(

        sessionUser?.username == 'juan@hazling.com' ||
        sessionUser?.username == 'diribarren' ||
        sessionUser?.username == 'asarkis@totalmundo.com' || 
        sessionUser?.username == 'jesuli.montenegro@hazling.com' || 
        sessionUser?.username == 'nathalyaespisona@totalmundo.com'

      ){
        this.supUse =true;

      }
            
 });

    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;
      if (this.queryParams?.code) {

        this.getPerson();
      }

    });

  }


  getBase_amount = async () =>  {
    try {

      let response =  await this._user.getBase_amount();
      this.base_amount = response?.amount;
    } catch (error) {

      console.log(error);
    
    }
  }


  getSection_transaction_by_person = async (buro:any = false) => {

    try {
      this.loading_buro = true;
      const resp = await this._user.getSection_transaction_by_person({ code: this.queryParams.code,type:'ONBOARDING' });

      let result = resp?.resp;

      if(result?.section_transactions?.length > 4){
        this.clientAprov = true;

    }


    result?.section_transactions.map((obj: any) => {

      let section_transaction = obj?.section_transaction;

    if (section_transaction?.code == "BURO" && obj.condition == "COMPLETE") {
      this.getDataBuroSimple(this.person, false);
    }
    return obj;
  });


  
    } catch (error) {
      
    }
    this.loading_buro = false;


    this.loading = false;

  }
  



  getDataBuroSimple = async (item: any, reload = true) => {

 this.loading_buro = true;
    if (item?.identitys?.length > 0) {

      let params = {
        "type_identity": item?.identitys[0]?.type_identity?.additionalType,
        "identity": item?.identitys[0]?.identity,
        "lastName": item?.person?.lastName,
        "code_person": item?.person?.code
      }

      try {
        const buro = await this._user.getDataBuro(params);
  
        this.buro = buro?.resp;
  
        if(this.buro.status_approved == "APPROVED"){
          
          this.clientAprovBuro= true;
  
        }
      } catch (error) {
        console.log(error);
        
      }
    
      this.loading_buro = false;

     
    }

  }


restClientBuro = async () =>  {


    let params = {
    
      "client": {
        "type_identity":this.person?.identitys[0]?.type_identity?.code ,
        "code_identity": this.person?.identitys[0]?.code_identity?.code,
        "identity":this.person?.identitys[0]?.identity 
      }
    
    };

  try {
       
    await this._user.clientQBuroReset(params);
    this.router.navigate([`/console/create-constacts`], {});

   }  catch (error:any) {
       console.log(error);
}


}

  getPerson = async () => {
    this.loading = true;

    try {
      const resp = await this._user.getPersonbyCode({ code: this.queryParams.code });

    this.getSection_transaction_by_person();

    this.person = resp.resp;
    } catch (error) {
      console.log(error);
      
    }
    
    this.loading = false;


  }


  getIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

  }


  getContact(item: any) {

    if (!(item?.constacts?.length > 0))
      return "";

    return `${item?.constacts[0]?.contact}`;

  }


  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }



  public redirect = () => {
    
    let Doc_ID;
    let Type_ID;
    let TLF;


    if (this.person?.identitys?.length > 0) {

      const data = this.person?.identitys[0];
      Type_ID = data.type_identity?.additionalType;
      Doc_ID = data.identity;

    }


    const data = {

      CED: environment.CED,
      IP: environment.IP_LOCAL,
      Type_ID,
      Doc_ID,
      PN: this.person?.person?.name,
      PA: this.person?.person?.lastName,
      FN: this.datePipe.transform(this.person?.person?.birth_date, 'yyyy-MM-dd'),
      GN: this.person?.person?.gender,
      TLF: "",
      EMAIL: ""

    }

    this.redirecFormService.postRedirect(environment.externalPsicometrico, data);

  }

  

  pocisionBottom = async (content: TemplateRef<any>,data:any= {}) =>  {

    try {
      this.loadQuota = true;

       let result =  await this.submit();
        this.offcanvasService.open(content, { position: 'bottom',keyboard: false} );
    
    } catch (error) {
      console.log(error);
      
    }
       
    this.loadQuota = false;

  }


  submit = async () =>  {

    return new  Promise( async (resolve, reject)  => {

      
      this.identityResponse = `${this.person?.identitys[0]?.code_identity?.name}-${this.person?.identitys[0]?.identity}`;

      let params = {
      
        "client": {
          "type_identity": this.person?.identitys[0]?.type_identity?.code ,
          "code_identity":  this.person?.identitys[0]?.code_identity.code,
          "identity": this.person?.identitys[0]?.identity
        }
      
      };


    try {
         
      let resp = await this._user.clientQuotaBasic(params);

      this.searchPerson =resp?.res?.person;

      this.usQuote = this.searchPerson?.quota_active;
      this.usQuoteClien = this.searchPerson?.quota_client_active;


      this.countInvoice = resp?.res?.countInvoice;
    

      resolve(true);


     }  catch (error:any) {
        console.log(error);
         
        reject(error);
     }

    });
}

offcanvasServiceClose(){
  this.offcanvasService.dismiss();

}


activeCupo = async (code:any) =>  {
  let params = {
  
    "code_person": code,
    "id_ejecutive":this._auth.sessionUser?.uid

  };

try {
     
  let resp = await this._user.activeCupo(params);
  this.getPerson();

 }  catch (error:any) {
     console.log(error);
}


}


toogleStatus = async (st = this.usQuote) => {
    
  let status = st? true:false;
  try {
    let query = {
      code_person:this.searchPerson?.code,
      status 
    };
    
     await this._user.status_quote_user(query);
     alertifyjs.success('Cambio de estatus exitoso');

     this.getPerson();

  } catch (error) {

    this.usQuote = !this.usQuote;
    alertifyjs.error('Error al cambiar estatus');
    console.log(error);
    
  }


}


toogleStatusClient = async (st = this.usQuoteClien) => {
    
  let status = st?true:false;


  try {
    let query = {
      status,
      code_person: this.person?.person?.code
    };

    debugger
    const resp = await this._user.toogleStatusClient(query);

    this.getPerson();

  } catch (error) {
    this.usQuoteClien = !status;
    console.log(error);
    
  }


}

}
