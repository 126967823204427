import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'akeela-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

    templates: any = {};
    object: any = {};
    form:any;
    submit_disabled = false;
    lang:any;
    errors:any = [];

    correoMinLength:any;
    correoMaxLength:any;
    passwordMinLength:any;
    passwordMaxLength:any;

    typeInputPass = false;

    correo:any;
    ip:any;
    password:any;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        public _auth : AuthService
    ) {
      
     }

    ngOnInit() {
        
      
        this.passwordMinLength = 6;
        this.passwordMaxLength = 12;

        this.form = this.formBuilder.group({
            
            correo: ['', [Validators.required]],
            password: ['', [Validators.required, Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]]
       
        });
    }


    submit = async () => {

      console.clear();
        this.errors = [];
        if ( this.form.valid) {
            this.submit_disabled = true;
            this.password = this.form.get('password').value;

            const body = {
                username: this.form.get('correo').value,
                password:this.form.get('password').value,
            }

            try {
         
               let resp = await this._auth.login(body);
               
               this._auth.setAuth(resp);
               this.router.navigate([environment.redirectRoute], {});
               
             } catch (error:any) {

                if(error?.error?.errors){
                    this.errors = error?.error?.errors;
                }

                if(error?.error?.msg){
                    this.errors =   [...this.errors,{msg:error?.error?.msg} ];
                }

                console.error(error);
                this.submit_disabled = false;

         
             }

        }
    }
  
    reloadLS(){

       localStorage.clear();
       location.reload()

    }
}
