import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as alertifyjs from 'alertifyjs';
import Swal from 'sweetalert2';
import { DirectDebitService } from '../../services/direct-debit/direct-debit.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-dashboard-direc-debit-alliances',
  templateUrl: './dashboard-direc-debit-alliances.component.html',
  styleUrls: ['./dashboard-direc-debit-alliances.component.css'],
  providers: [DatePipe]
})
export class DashboardDirecDebitAlliancesComponent implements OnInit {

  loading = true;
  sessionUser: any;
  profiles: any;
  profile: any;
  current_direct_debit: any;
  status_sections: any = [];
  current_status_sections: any = [];
  loading_section: any = [];
  show_conciliacion_button = false;
  obj_conciliacion: any;
  provider: any = {
    tax_id_letter: 'J',
    tax_id: '311501878',
    tax_id_verification: '0',
  };
  template: string = '/lotes/bdv/send_final';
  sections: any = [
    {
      id: 1,
      name: 'Generar Domiciliación',
      status: 'INITIAL',
    },
    {
      id: 2,
      name: 'Descargar XSLX',
      status: 'GENERATED',
    },
    {
      id: 3,
      name: 'Descargado',
      status: 'DOWNLOADED',
    },
    {
      id: 4,
      name: 'Domiciliación Recibida por Aliado',
      status: 'UPLOADED',
    },
    {
      id: 5,
      name: 'Procesar Respuesta del Aliado',
      status: 'PROCESING',
    },
    {
      id: 6,
      name: 'Finalizado',
      status: 'FINISHED',
    },
  ];

  types: any = [
    {
      id: 1,
      name: 'Diario',
    },
    {
      id: 2,
      name: 'Masivo',
    },
    {
      id: 3,
      name: 'Morosos',
    },
  ];

  allies: any = [];

  pending: any;

  view = 1;
  form: FormGroup | any = this.formBuilder.group({
    allye: [''],
    type: [''],
  });
  files: any = [];

  date: any = new Date();
  current_date: any = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  constructor(
    private userService: UserService,
    private offcanvasService: NgbOffcanvas,
    private formBuilder: FormBuilder,
    private directDebitService: DirectDebitService,
    public _auth: AuthService,
    private datePipe: DatePipe
  ) {}

  async ngOnInit() {
    this.sessionUser = this._auth.sessionUser;
    this.profiles = this._auth.getProfiles();
    this.profile = this.getProfile(this.sessionUser);
    this.allies = await this.getAllies();
    this.allies = this.allies.res;
    this.setDefault();
    this.updateCurrentDirectDebit();
    
  }

  getReadXLS = () => {
    alert('');
  }

  getCurrentDate = async () => {
    this.pending = await this.directDebitService.pendingLoteGeneric({ 
      ally:  this.form.getRawValue().allye
    });
    if (this.pending.resp.length > 0) {
      this.date = this.datePipe.transform(
        this.pending.resp[0].date,
        'yyyy-MM-dd'
      );
    } else {
      this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
  };

  getProfile(user: any) {
    let profiles = user?.profile;
    let profile;
    if (profiles) {
      let value = this.profiles[profiles[0]];
      let key = profiles[0];
      profile = { key, value };
    }
    return profile;
  }

  updateStatusSections = async () => {
    await this.getStatusCurrentDirectDebit();
    let iter_section;
    this.sections.map((section: any) => {
      this.status_sections[section.status] = 'PENDING';
      iter_section = this.validateSection(section.status);
      if (iter_section) {
        this.status_sections[section.status] = iter_section;
      }
    });
    this.loading = false;
  };

  validateSection = (status: string) => {
    return this.current_status_sections.find((section: any) => {
      return section.status === status;
    });
  };

  updateCurrentDirectDebit = async () => {
    this.getCurrentDate();
    this.current_direct_debit = null;
    this.files = [];
    this.current_direct_debit = await this.getDebitDirectByAllyAndType(
      this.form.getRawValue().allye,
      this.form.getRawValue().type,
      this.date
    );
    console.log('Aquiii');
    console.log(this.current_direct_debit);
    if (this.current_direct_debit) {
      if (this.current_direct_debit.res.length > 0) {
        this.current_direct_debit = this.current_direct_debit.res[0];
      } else {
        this.current_direct_debit = null;
      }
    } else {
      this.current_direct_debit = null;
    }
    this.updateStatusSections();
  };

  analiceFile = () => {};

  getStatusCurrentDirectDebit = async () => {
    if (this.current_direct_debit) {
      this.current_status_sections = await this.getDebitDirectStatuses(
        this.current_direct_debit._id
      );
      this.current_status_sections = this.current_status_sections.res;
    } else {
      this.current_status_sections = [];
    }
  };

  setDefault = () => {
    console.log('Aquiii: ');
    console.log(this.allies[0].code);
    if (this.allies.length === 1) {
      this.form.get('allye').setValue(this.allies[0].code);
      this.form.get('allye').patchValue(this.allies[0].code);
    }
    if (this.types.length >= 1) {
      this.form.get('type').setValue(this.types[0].id);
      this.form.get('type').patchValue(this.types[0].id);
    }
  };

  getDebitDirectByAllyAndType = (
    ally: string,
    type: string,
    date: any
  ): Promise<any> => {
    return this.directDebitService.getDirectDebitAlly(ally, type, date);
  };

  getDebitDirectByBankAndType = (
    bank: string,
    type: string,
    date: any
  ): Promise<any> => {
    return this.directDebitService.getDirectDebit(bank, type, date);
  };

  getDebitDirectStatuses = (direct_debit_id: string): Promise<any> => {
    return this.directDebitService.getDirectDebitStatuses(direct_debit_id);
  };

  getAllies = async () => {
    return await this.userService.getAllies({});
  };

  openModalBanks = (content: TemplateRef<any>, data: any = {}) => {
    try {
      this.offcanvasService.open(content, {
        position: 'bottom',
        keyboard: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  pendingDirectDebit = async () => {
    return await this.directDebitService.pendingLoteGeneric({ 
      ally:  this.form.getRawValue().allye
    });
  };

  
  startDirectDebit = async () => {
    //this.testPending();
    let pending: any = await this.pendingDirectDebit();
    if (pending?.resp.length === 0) {
      this.loading_section['INITIAL'] = true;
      let body = {
        type: this.form.getRawValue().type,
        allye: this.form.getRawValue().allye,
        user: this.sessionUser.uid,
        provider: this.provider,
      };

      console.log(body);
      let response = await this.directDebitService.startDirectDebitAllianceProcess(
        body
      );


      if (response) {
        if (response.data.length > 0) {
          await this.updateCurrentDirectDebit();
          delete this.loading_section['INITIAL'];
        } else {
          alertifyjs.error(
            'No hay pagos pendientes que cumplan con los críterios seleccionados'
          );
          delete this.loading_section['INITIAL'];
        }
      } else {
        alertifyjs.error(
          'No hay pagos pendientes que cumplan con los críterios seleccionados'
        );
        delete this.loading_section['INITIAL'];
      }
    } else {
      alertifyjs.error(
        'Debe culminar el proceso activo para comenzar con un nuevo proceso de domiciliación'
      );
    }
  };

  downloadCheck = async () => {
    if(this.status_sections['FINISHED']==='PENDING' && this.status_sections['PROCESING']==='PENDING' && this.status_sections['UPLOADED']==='PENDING'){
      let body = {
        direct_debit_id: this.current_direct_debit._id,
        status: 'DOWNLOADED',
        user: this.sessionUser.uid,
      };
      console.log(body);
      await this.directDebitService.saveDirectDebitStatus(body);
      await this.updateCurrentDirectDebit();
    }
  };

  offcanvasServiceClose() {
    this.view = 1;
    this.offcanvasService.dismiss();
  }

  reset = (evt: any) => {
    this.show_conciliacion_button = false;
    this.obj_conciliacion = null;
  };

  getResponse = (response: any) => {
    console.log("Respuesta interpretada");
    console.log(response);
    if (response.response==="ok") {
      this.show_conciliacion_button = true;
      this.obj_conciliacion = response;
    } else {
      this.show_conciliacion_button = false;
      this.obj_conciliacion = null;
    }
  };

  conciliar = async () => {
    // validación inicial...

    Swal.fire({
      title: 'Confirmación',
      html: '¿Confirma que los fondos fueron recibidos conforme a los montos detallados?',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: `Si!`,
      cancelButtonText: `Cancelar!`,
      showLoaderOnConfirm: true,

      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        // fin validación
        if (this.current_direct_debit) {
          this.loading_section['PROCESING'] = true;
          let header = this.obj_conciliacion.header;
          // Mario
          let response = this.obj_conciliacion.body;

          let obj = {
            bank: this.allies[0].bank,
            account_payment: this.allies[0].account_payment,
            ally: this.current_direct_debit.ally,
            tax_id: this.provider.tax_id_letter + this.provider.tax_id,
            id_direct_debit: this.current_direct_debit._id,
            token: localStorage.getItem('x-token'),
            obj: this.formatElements(response),
            date: header['fecha_valor'].split('/').reverse().join('/'),
            rate: this.obj_conciliacion.rate
          };

        //   let obj = {
        //     "bank": "653c3e594ee3d3296757285d",
        //     "tax_id": "J311501878",
        //     "id_direct_debit": "65b8dbb2585f783e7ae94803",
        //     "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI2M2RkNTQwN2QyYjMxYjUwYWZjNjI3ZjYiLCJ1c2VyIjp7InVzZXJuYW1lIjoianVhbkBoYXpsaW5nLmNvbSIsIm5hbWUiOiJqdWFuIiwibGFzdE5hbWUiOiJzb3RvIiwicm9sZSI6IlVTRVJfUk9MRSIsInBlcnNvbl9jb2RlIjoiYzE4Mzk1Y2EtMWIyMS00MDIyLWEwMTQtODEwZTlmZGY0NjJjIiwidWlkIjoiNjNkZDU0MDdkMmIzMWI1MGFmYzYyN2Y2IiwiaWRlbnRpdHkiOltdLCJwcm9maWxlIjpbIk9XTkVSIl19LCJpYXQiOjE3MDY3MTEwNjAsImV4cCI6MTcwNjc5NzQ2MH0.4wb8J72CUcEgLtrMq8NsG8PQqQvAHcKUaftnO7aZrso",
        //     "obj": [
        //       {
        //         "res": "02",
        //         "success": true,
        //         "identity": "V19636213",
        //         "num_cuenta": "01020330900000534725",
        //         "reference": "00000000000000430952",
        //         "amount": "298.5",
        //         "description": "Procesado                                                                                              ",
        //         "num_credit": "43095"
        //     }
        //     ],
        //     "date": "2024/01/30",
        //     "rate": 36.14
        // };

          console.log(header);
          console.log(obj);
          console.log(response);

          try {
            // inicia el proceso de conciliación
            try{
              let data_base: any = obj?.obj;
              let response_conciliation = await this.directDebitService.conciliarAlianza(
                obj
              );
              

              let body = {
                direct_debit_id: this.current_direct_debit._id,
                status: 'PROCESING',
                user: this.sessionUser.uid,
                url: this.obj_conciliacion.file.url,
              };
              await this.directDebitService.saveDirectDebitStatus(body);
              
              let { file, file_result, file2, res } = response_conciliation.resp;

              // aquí se agregará el proceso de validación de la conciliación

              // header[0]['CODNEGO']

              let body_2 = {
                direct_debit_id: this.current_direct_debit._id,
                status: 'FINISHED',
                user: this.sessionUser.uid,
                url: file?.url,
                url_failed: file2?.url,
                url_resume: file_result?.url,
                negociation_code: ""
              };
              await this.directDebitService.saveDirectDebitStatus(body_2);


              let body3 = {
                id: this.current_direct_debit._id,
                rate: this.obj_conciliacion.rate
              };
              await this.directDebitService.update_rate_direct_debit(body3);



              await this.updateCurrentDirectDebit();
              this.show_conciliacion_button = false;
              this.loading_section['PROCESING'] = false;
            } catch(e) {
              console.log(e);
              alertifyjs.error(
                'Ha ocurrido un error en el proceso de conciliación, contacte con el administrador o reintente conciliar.'
              );
              this.show_conciliacion_button = true;
              this.loading_section['PROCESING'] = false;
            }
          } catch (error) {
            console.log(error);
            this.show_conciliacion_button = true;
            this.loading_section['PROCESING'] = false;
          }
        
        }
      }
    });
  
  };

  conciliar_manual = async () => {
     
      let direct_debit_id = "65b39621ad29e23a8eb2d285";
      let obj = {
        "bank": "653c3e594ee3d3296757285d",
        "tax_id": "J311501878",
        "id_direct_debit": direct_debit_id,
        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiI2M2RkNTQwN2QyYjMxYjUwYWZjNjI3ZjYiLCJ1c2VyIjp7InVzZXJuYW1lIjoianVhbkBoYXpsaW5nLmNvbSIsIm5hbWUiOiJqdWFuIiwibGFzdE5hbWUiOiJzb3RvIiwicm9sZSI6IlVTRVJfUk9MRSIsInBlcnNvbl9jb2RlIjoiYzE4Mzk1Y2EtMWIyMS00MDIyLWEwMTQtODEwZTlmZGY0NjJjIiwidWlkIjoiNjNkZDU0MDdkMmIzMWI1MGFmYzYyN2Y2IiwiaWRlbnRpdHkiOltdLCJwcm9maWxlIjpbIk9XTkVSIl19LCJpYXQiOjE3MDY3OTkxMDUsImV4cCI6MTcwNjg4NTUwNX0.EYoFYn9k7RfPhDezp-y5FfDGXu53yI84MGr2ba3Fcyo",
        "obj": [
    
            {
                "res": "02",
                "success": true,
                "identity": "V24523186",
                "num_cuenta": "01020388100000222707",
                "reference": "00000000000000460542",
                "amount": "0.87",
                "description": "Procesado con Cobro Parcial                                                                                             ",
                "num_credit": "46054"
            }
        ],
        "date": "2024/01/26",
        "rate": 36.12
    };


      try {
        
        let response_conciliation = await this.directDebitService.conciliar(
          obj
        );

        console.log(response_conciliation);
        
        
        
      } catch (error) {
        console.log(error);
        this.show_conciliacion_button = true;
        this.loading_section['PROCESING'] = false;
      }

  };

  formatDataToExcel = (obj: any) => {
    let response: any = [];

    obj.map((element: any) => {
      console.log(element);
      response = [
        ...response,
        {
          CEDULA: element.identity,
          CREDITO: element.num_credit,
          REFERENCIA: element.reference,
          'NUMERO DE CUENTA': element.num_cuenta,
          'PROCESADO POR EL BANCO': element.success ? 'SI' : 'NO',
          DESCRIPCION: element.description,
          CONCILIADO: element?.conciliation?.reconciled_payment ? 'SI' : 'NO',
          MONTO: element.conciliation
            ? this.toFixedApp(
                element.conciliation.amount * element.conciliation.rate
              )
            : 0,
        },
      ];
    });
    return response;
  };

  toFixedApp = (x: any) => {
    let a: any = `e+${2}`;
    let b: any = `e-${2}`;
    return +(Math.round(x + a) + b);
  };

  findConciliationObject = (obj: any, reference: any) => {
    return obj.find((element: any) => element.paymentMethodId === reference);
  };

  getElements = (obj: any, type: any) => {
    let response: any = [];
    obj
      .filter((row: any) => row.TIPOREG !== '01')
      .map((row: any) => {
        if (row.TIPOREG === '02') response = [...response, row];
        if (row.TIPOREG === '03') {
          let position = response.findIndex((ele: any) => {
            return ele.REFDEB === row.REFDEB && !ele.ERROR;
          });
          if (position != -1) {
            console.log(response[position]['TOTCOB']);
            if(response[position]['TOTCOB']==="0.00"){
              response[position]['ERROR'] = row;
            }
          }
        }
      });
    return response;
  };

  confirm_upload = async () => {
    Swal.fire({
      title: 'Confirmación',
      text: 'Solo debe confirmar cuando el archivo se haya recibido de forma exitosa por el Aliado ¿Desea confirmar?',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: `Si!`,
      cancelButtonText: `Cancelar!`,
      showLoaderOnConfirm: true,

      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.loading_section['UPLOADED'] = true;
        let body_2 = {
          direct_debit_id: this.current_direct_debit._id,
          status: 'UPLOADED',
          user: this.sessionUser.uid,
          url: undefined,
        };
        await this.directDebitService.saveDirectDebitStatus(body_2);
        this.loading_section['UPLOADED'] = false;
        await this.updateCurrentDirectDebit();
      }
    });
  };

  cancel_process = () => {
    Swal.fire({
      title: 'Alerta',
      text: '¿Desea cancelar el proceso, esta acción no se puede revertir, desea continuar?',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: `Si!`,
      cancelButtonText: `Cancelar!`,
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.loading_section['UPLOADED'] = true;
        let body = {
          id: this.current_direct_debit._id,
          user: this.sessionUser.uid,
        };
        await this.directDebitService.cancel_direct_debit(body);
        this.loading_section['UPLOADED'] = false;
        await this.updateCurrentDirectDebit();
      }
    });
  };

  getHeader = (obj: any, type: any) => {
    let response: any = [];
    return obj.filter((row: any) => row.TIPOREG === '01');
  };

  finalizar = async() => {
    let body = {
      id: this.current_direct_debit._id
    };
    await this.directDebitService.finish_direct_debit(body);
    this.updateCurrentDirectDebit();
  }

  formatElements = (elements: any) => {
    let response: any = [];
    let element: any;
    let type = "";
    
    elements.map((ele: any) => {
      if(ele["monto pagado"]>0) {
        type="02";
      } else {
        type="03";
      }
      element = {
        res: "02",
        success: type==="03" ? false : true,
        identity: ele["codigo"]+ele["cedula"].padStart(8, "0"),
        num_cuenta: "",
        reference: (ele["credito"]+""+ele["cuota"]).toString().padStart(20, "0"),
        amount: ele["monto_pagado_bs"].toString(),
        description: type==="03"? "Pago no realizado": (ele["monto pagado"]<ele["monto adeudado"]?"Cobro Parcial":"Pago Realizado") ,
        num_credit: ele["credito"].toString(),
        error: type==="03" ? {
          CODERROR: "1",
          DESCRIPCION: "Pago no realizado por el Aliado."
        } : undefined,
      };
      response = [...response, element];
    });
    return response;
  };

  downloadFile = async (url:any, extension: string, name: string) => {
    let res = await this.directDebitService.downloadImage(url, extension);
    this.downloadCheck();
    if(res) {
      saveAs(res, `${name}.${extension}`);
    }
  }

}
