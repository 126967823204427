<div *ngIf="!isConnected" [ngClass]="{'toast-danger': status != 'ONLINE','toast-success': status == 'ONLINE'}" class="ak-alert toast botton-left    {{!isConnected?'animate__animated animate__fadeIn animate__faster ':'' }}">

    <div class="toast-body">
        <span *ngIf="status == 'ONLINE'">
            Conexión restablecida
        </span>
        <span *ngIf="status == 'OFFLINE'">
            Se está intentando establecer la conexión...<br> Revise su conexión a la red
        </span>
    </div>
</div>

<!-- <div *ngIf="status == 'OFFLINE'" class="capa-menu"></div> -->
<app-biometria *ngIf="biometria">
</app-biometria>
<div class="pb-5 mb-3" *ngIf="!biometria">
    <app-navbarauth *ngIf="showNav "></app-navbarauth>

    <router-outlet></router-outlet>
</div>


<div *ngIf="isAuth && activeMenu && !biometria" class="d-block d-md-none">
    <div class="md-pic-edit">

        <mbsc-optionlist [options]="settings">

            <mbsc-option-item data-text="Activos">
                <div (click)="setOptionMenu(1);closeOffcanvas()" [routerLink]="['/console/create-constacts']">
                    <div class="icon-menu-mbs" [ngClass]="{'active': menuOption == 1}">
                        <i class="  fas fa-users"></i>
                    </div>
                    <div class="text-center active-cursor">
                        <div class="active-cursor text-center text-secondary fz-12 ">
                            Contactos
                        </div>
                    </div>

                </div>
            </mbsc-option-item>


            <mbsc-option-item data-text="Consolidado" *ngIf="_auth.IsPermitid(['OWNER','ANALIST'])">
                <div (click)="setOptionMenu(3);closeOffcanvas()" [routerLink]="['/console/finacial-dashboard']">
                    <div class="icon-menu-mbs" [ngClass]="{'active': menuOption == 3}">
                        <i class="fas fa-piggy-bank"></i>
                    </div>
                    <div class="text-center active-cursor">
                        <div class="active-cursor text-center text-secondary fz-12 ">
                            Finanzas
                        </div>
                    </div>

                </div>
            </mbsc-option-item>

            <mbsc-option-item data-text="Consolidado" *ngIf="_auth.IsPermitid(['OWNER','ANALIST'])">
                <div (click)="setOptionMenu(2);closeOffcanvas()" [routerLink]="['/console/estadisticas']">
                    <div class="icon-menu-mbs" [ngClass]="{'active': menuOption == 2}">
                        <i class="fas fa-tablet-alt"></i>
                    </div>
                    <div class="text-center active-cursor">
                        <div class="active-cursor text-center text-secondary fz-12 ">
                            Estadísticas
                        </div>
                    </div>

                </div>
            </mbsc-option-item>


            <mbsc-option-item data-text="Consolidado" *ngIf="_auth.IsPermitid(['OWNER','ANALIST'])">
                <div (click)="setOptionMenu(5);closeOffcanvas()" [routerLink]="['/console/credit-option']">
                    <div class="icon-menu-mbs" [ngClass]="{'active': menuOption == 5}">
                        <i class="fas fa-terminal"></i>
                    </div>
                    <div class="text-center active-cursor">
                        <div class="active-cursor text-center text-secondary fz-12 ">
                            Consola
                        </div>
                    </div>

                </div>
            </mbsc-option-item>
        </mbsc-optionlist>

    </div>
</div>