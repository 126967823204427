
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { UserService } from 'src/app/services/user/user.service';
import * as alertifyjs from 'alertifyjs';

@Component({
  selector: 'app-working-condition',
  templateUrl: './working-condition.component.html',
  styleUrls: ['./working-condition.component.css']
})
export class WorkingConditionComponent implements OnInit {
  @Input() code_person: any;
  @Output() changeView = new EventEmitter<any>();

  form:any;
  submit_disabled:any;


    errorMsg:any;
  loadForm:any;
  section_transactions:any = [];
  personAddress:any = [];
  section_complete:any;
  customer:any;
  constructor(   
     private _common: CommonService,
     private formBuilder: FormBuilder,
     private _user:UserService,

    ) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({

      amount: ['',[Validators.required]]

  });

  this.getCustomer();

}

getCustomer = async () =>  {

  try {
   // this.loadForm = true;

   let resp =  await this._common.getcustomer({ code_person:this.code_person});
   this.customer = resp.resp;

   this.form = this.formBuilder.group({
    condition: [this.customer?.working_condition?this.customer?.working_condition:'' ,[Validators.required]],
    description: [this.customer?.working_condition_descripion?this.customer?.working_condition_descripion:'' ],

});
  } catch (error) {
    console.log(error);
    
  }

}


submit = async () =>  {

  if ( this.form.valid ) {
      this.submit_disabled = true;
      const data = this.form.getRawValue();

    let params = {
      code_person:this.code_person,
      condition:data.condition,
      description:data.description
  };


  try {
       
    let resp = await this._common.working_condition(params);
    alertifyjs.success('Registro exitoso');

    this.submit_disabled = false;


   }  catch (error:any) {

        const params = error?.error?.error;
        if(params?.code){
          this.errorMsg = params?.msg;
        }

      this.submit_disabled = false;
   }
}

}


setChange(View:any){
    
  this.changeView.emit(View);

 }

}