
import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { SocketioService } from '../../services/socket/socketio.service';
import { ConnectionService } from 'ng-connection-service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {

 
  usersActives:any = [];
  usersMsg:any = [];
  isAuth:any;
  
form:any  = {
  user:"",
  mensaje:"",
}

uid:any;

  constructor(
    public _auth : AuthService,
    public router: Router,
    private connectionService: ConnectionService,
    private offcanvasService: NgbOffcanvas,
    public socketService: SocketioService


 ) {
 }


  ngOnInit() {

  
    this.socketService.usersActives.subscribe((resp: any) => {

      this.usersActives = resp.filter((item:any) => item.uid != this.uid);

    });

    this.socketService.usersMsg$.subscribe((rep: any) => {


      this.usersMsg = rep;


    });


    

    this._auth.auth$.subscribe((rep: any) => {

      if(rep){


        this.uid = this._auth.sessionUser?.uid;
        
        this.usersActives =  this.usersActives.filter((item:any) => item.uid != this.uid);
      }
      this.isAuth = rep;

    });


  }
  
  
enviarMensaje(){

  this.socketService.mensajePrivado(this.form.user,this.form.mensaje);

  this.socketService.setUsersMsg( { de: `Tu`,mensaje: this.form.mensaje });

  this.form  = {
    user:"",
    mensaje:"",
  }

}

}

